import { Button, Form, Space, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  EditOutlined,
  FilterOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { viewRequest } from "./viewRequest";
import { ModalDelete } from "./componentes/modais/delete";
import { DrawerFilter } from "./componentes/drawer";
import { colors } from "../../Utils/temas";
import { ModalInsert } from "./componentes/modais/insert";

export const View = () => {
  const [view, setView] = useState([]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [idView, setIdView] = useState(null);
  const [idDelete, setIdDelete] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  const [filtro, setFiltro] = useState({});

  const [form_pesquisa] = Form.useForm();

  useEffect(() => {
    listaView(1, 10, filtro);
  }, [filtro]);

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    { title: "Nome", dataIndex: "nome" },
    {
      title: "Em Teste",
      dataIndex: "em_teste",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      title: "Ações",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Editar">
            <EditOutlined
              onClick={() => {
                setOpen(true);
                setIdView(record.id);
              }}
            />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined
              onClick={() => {
                setOpenDelete(true);
                setIdDelete(record.id);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const listaView = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await viewRequest.getViews(page, pageSize, params);
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setView(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onPesquisarClick = (value) => {
    setFiltro(value);
    listaView(1, 10, value);
    setOpenFilter(false);
  };

  const onRemoveTags = (key) => {
    const novosFiltros = { ...filtro };
    delete novosFiltros[key];

    setFiltro(novosFiltros);
  };

  const tags = {
    nome: "Nome",
    em_teste: "Em teste",
  };

  const getTags = () => (
    <>
      {Object.entries(filtro)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagsColors = () => {
            if (key === "nome") {
              return "magenta";
            } else if (key === "em_teste" && value) {
              return "green";
            }
            return "red";
          };
          return (
            <>
              {value !== "" && (
                <Tag
                  key={key}
                  closable
                  onClose={() => onRemoveTags(key)}
                  color={tagsColors(key)}
                >
                  <text style={{ padding: 10 }}>
                    {tags[key] +
                      ": " +
                      (key === "em_teste" ? (value ? "Sim" : "Não") : value)}
                  </text>
                </Tag>
              )}
            </>
          );
        })}
    </>
  );

  return (
    <>
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={() => setOpen(true)}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          <PlusOutlined />
          Inserir
        </Button>

        <Button
          onClick={() => setOpenFilter(true)}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          <FilterOutlined />
          Filtros
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={view}
        pagination={{
          total: totalRecords,
          showTotal: (total) => (
            <span
              style={{ fontWeight: "500", position: "absolute", left: "4%" }}
            >
              Quantidade: {total}
            </span>
          ),
          onChange: (page, pageSize) => {
            listaView(page, pageSize, filtro);
          },
        }}
        loading={loading}
        title={getTags}
      />

      <ModalDelete
        onCancel={() => setOpenDelete(false)}
        visible={openDelete}
        id={idDelete}
        estadoView={setView}
      />

      <DrawerFilter
        visible={openFilter}
        onClose={() => setOpenFilter(false)}
        onCancel={() => {
          setOpenFilter(false);
          form_pesquisa.resetFields();
          setFiltro({});
          listaView(1, 10);
        }}
        form={form_pesquisa}
        onPesquisar={onPesquisarClick}
      />

      <ModalInsert
        estadoView={setView}
        onCancel={() => {
          setOpen(false);
          setIdView(null);
        }}
        visible={open}
        id={idView}
      />
    </>
  );
};
