import { Button, Modal, Result } from "antd";
import React from "react";

export const ModalStatusCreate = ({ visible, onCancel }) => (
  <Modal visible={visible} onCancel={onCancel} footer={null}>
    <Result
      status="success"
      title="Registro salvo com sucesso!"
      extra={[
        <Button type="primary" onClick={onCancel}>
          Ok
        </Button>,
      ]}
    />
  </Modal>
);

export const ModalStatusEdit = ({ visible, onCancel }) => (
  <Modal visible={visible} onCancel={onCancel} footer={null}>
    <Result
      status="success"
      title="Registro editado com sucesso!"
      extra={[
        <Button type="primary" onClick={onCancel}>
          Ok
        </Button>,
      ]}
    />
  </Modal>
);

export const ModalStatusDelete = ({ visible, onCancel }) => (
  <Modal visible={visible} onCancel={onCancel} footer={null}>
    <Result
      status="success"
      title="Registro deletado com sucesso!"
      extra={[
        <Button type="primary" onClick={onCancel}>
          Ok
        </Button>,
      ]}
    />
  </Modal>
);

export const ModalStatusErro = ({ visible, onCancel }) => (
  <Modal visible={visible} onCancel={onCancel} footer={null}>
    <Result
      status="500"
      title="Opa, ocorreu um erro!"
      extra={[
        <Button type="primary" onClick={onCancel}>
          Voltar
        </Button>,
      ]}
    />
  </Modal>
);
