import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import { KeyOutlined, CloseOutlined } from "@ant-design/icons";
import CartorioRequest from "../../Paginas/CadastroCartorio/CartorioRequest";
import { ModalError } from "./modalError";

function SelAtualizacao({
  onSelecionado,
  visible,
  onCancel,
  optionsUf,
  defaultValue,
}) {
  const [loading, setLoading] = useState(false);
  const [openError, setOpenError] = useState(false);

  const [switchValues, setSwitchValues] = useState({
    switchSituacao: false,
    switchApi: false,
    switchAto: false,
    switchOS: false,
    switchSMS: false,
    switchWhats: false,
    switchWorkflow: false,
  });

  const [currentPage, setCurrentPage] = useState(1);

  const [filtros, setFiltros] = useState({ situacao: "A" });
  const [sistemas, setSistemas] = useState([{ id: 0, nome_sistema: "" }]);

  const [listaCartorios_Quantidade, setListaCartorios_Quantidade] = useState({
    results: [],
    count: 0,
  });

  const [objetosSelecionados, setObjetosSelecionados] = useState([]);

  const [form_pesquisa] = Form.useForm();

  const [pageSize, setPageSize] = useState(10);

  function handleOk() {
    if (
      objetosSelecionados.every((item) => item.bloquear_atualizacao === false)
    ) {
      onSelecionado(objetosSelecionados);
    } else {
      setOpenError(true);
    }
    setCurrentPage(1);
    setPageSize(10);
  }

  const columns = [
    {
      title: "Nome Cartorio",
      dataIndex: "nome_cartorio",
    },
    {
      title: "CPF/CNPJ",
      dataIndex: "cpfcnpj",
    },
    {
      title: "Cidade",
      dataIndex: "cidade",
    },
    {
      title: "UF",
      dataIndex: "uf",
    },
  ];

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await CartorioRequest.getListaCartorio(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      const { count, results } = response.data;

      setListaCartorios_Quantidade({ count: count, results: results });
      setLoading(false);
    }
  }

  function SelecionarLinha(rowKeys, selectedRows) {
    setObjetosSelecionados(selectedRows);
  }

  async function onPesquisarClick(value) {
    setFiltros({
      ...value,
      situacao: "A",
      sistemas: value.sistemas && value.sistemas.join(","),
    });
  }

  const Limpar = () => {
    setObjetosSelecionados([]);
  };

  useEffect(() => {
    getSistemas();
  }, []);

  useEffect(() => {
    if (visible) {
      FetchRecords(1, pageSize, filtros);
    }
  }, [filtros, visible]);

  const handleSwitchChange = (name, checked) => {
    setSwitchValues((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const cartorioBloqueado = objetosSelecionados.filter(
    (item) => item.bloquear_atualizacao
  );

  const getSistemas = async () => {
    const response = await CartorioRequest.listarSistema();
    if (response.status === 200) {
      const { data } = response;
      setSistemas(data.results);
    }
  };

  return (
    <Modal
      width={900}
      title="Selecionar Cartório"
      visible={visible}
      onOk={handleOk}
      okButtonProps={{
        disabled: objetosSelecionados.length === 0 ? true : false,
      }}
      onCancel={() => {
        onCancel();
        setCurrentPage(1);
      }}
      forceRender={true}
      destroyOnClose={true}
      centered
      bodyStyle={{ height: "70vh", overflowX: "scroll" }}
    >
      <>
        <Form
          form={form_pesquisa}
          layout="vertical"
          onFinish={onPesquisarClick}
          preserve={true}
        >
          <Row justify="space-between">
            <Col span={10}>
              <Form.Item name="cidade" label="Cidade:" style={{ margin: 0 }}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item name="uf" label="UF" style={{ margin: 0 }}>
                <Select
                  name="uf"
                  options={optionsUf}
                  defaultValue={defaultValue}
                />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item
                name="nome_cartorio"
                label="Nome cartorio:"
                style={{ margin: 0 }}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item name="sistemas" label="Sistemas:">
                <Select
                  style={{ width: "100%" }}
                  options={sistemas.map((item) => ({
                    label: item.nome_sistema,
                    value: item.id,
                  }))}
                  mode="multiple"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} justify="space-around">
            <Col>
              <Form.Item
                name="usa_workflow"
                label="Usa Workflow"
                style={{ alignItems: "center" }}
              >
                <Switch
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  checked={switchValues.switchWorkflow}
                  onChange={(checked) =>
                    handleSwitchChange("switchWorkflow", checked)
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="usa_api"
                label="Usa api"
                style={{ alignItems: "center" }}
              >
                <Switch
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  checked={switchValues.switchApi}
                  onChange={(checked) =>
                    handleSwitchChange("switchApi", checked)
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="usar_cad_ato"
                label="Usa cad ato"
                style={{ alignItems: "center" }}
              >
                <Switch
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  checked={switchValues.switchAto}
                  onChange={(checked) =>
                    handleSwitchChange("switchAto", checked)
                  }
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="usa_os"
                label="Usa OS"
                style={{ alignItems: "center" }}
              >
                <Switch
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  checked={switchValues.switchOS}
                  onChange={(checked) =>
                    handleSwitchChange("switchOS", checked)
                  }
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="usa_sms"
                label="Usa SMS"
                style={{ alignItems: "center" }}
              >
                <Switch
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  checked={switchValues.switchSMS}
                  onChange={(checked) =>
                    handleSwitchChange("switchSMS", checked)
                  }
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="usa_whatszap"
                label="Usa WhatsZap"
                style={{ alignItems: "center" }}
              >
                <Switch
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  checked={switchValues.switchWhats}
                  onChange={(checked) =>
                    handleSwitchChange("switchWhats", checked)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} style={{ marginBottom: 10 }}>
            <Col span={12}>
              <Button htmlType="submit">
                <KeyOutlined />
                Pesquisar
              </Button>
            </Col>

            <Col span={3} push={9}>
              <Button onClick={Limpar}>
                <CloseOutlined />
                Limpar
              </Button>
            </Col>
          </Row>
        </Form>
      </>
      <Table
        loading={loading}
        columns={columns}
        size="small"
        rowKey={"id"}
        rowSelection={{
          onChange: SelecionarLinha,
          preserveSelectedRowKeys: true,
        }}
        dataSource={listaCartorios_Quantidade.results}
        pagination={{
          pageSize,
          current: currentPage,
          total: listaCartorios_Quantidade.count,
          showTotal: (total) => (
            <span
              style={{ fontWeight: "500", position: "absolute", left: "4%" }}
            >
              Quantidade: {total}
            </span>
          ),
          onShowSizeChange: (item, size) => setPageSize(size),
          onChange: (page, pagaSize) => {
            setCurrentPage(page);
            FetchRecords(page, pagaSize, filtros);
          },
          locale: { items_per_page: "" },
        }}
      />

      <ModalError
        onClose={() => setOpenError(false)}
        text={`Não é possível continuar, o ${
          openError && cartorioBloqueado[0].nome_cartorio
        } está com sua atualização bloqueada`}
        visible={openError}
      />
    </Modal>
  );
}
export default SelAtualizacao;
