/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Switch,
  Skeleton,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { comandosRequest } from "../comandosRequest";
import {
  ModalStatusCreate,
  ModalStatusEdit,
  ModalStatusErro,
} from "../../../Utils/modalStatus";
import { ufs } from "../../../Constante/UFS";

export const ModalInserir = ({
  visible,
  onCancel,
  estadoComandos,
  id,
  optionsSistemas,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      getComandos();
    }
  }, [id]);

  const { TextArea } = Input;

  const postComandos = async (data) => {
    setConfirmLoading(true);
    try {
      const response = await comandosRequest.postComandos(data, id);
      if (response.status === 201) {
        estadoComandos((prevState) => [response.data, ...prevState]);
        onCancel();
        form.resetFields();
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        estadoComandos((prevState) => prevState.map(cb));
        onCancel();
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
        form.resetFields();
      }
    } catch (error) {
      setOpen(true);
      setConfirmLoading(false);
    }
  };

  const close = () => {
    onCancel();
    form.resetFields();
  };

  const getComandos = async () => {
    setLoading(true);
    try {
      const response = await comandosRequest.getComandosId(id);
      const { data } = response;
      if (response.status === 200) {
        form.setFieldsValue({
          descricao: data.descricao,
          texto_firebird: data.texto_firebird,
          em_teste: data.em_teste,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const options = ufs.map((uf) => {
    return {
      label: uf.sigla,
      value: uf.sigla,
    };
  });

  return (
    <>
      <Modal
        visible={visible}
        onCancel={close}
        footer={null}
        width={800}
        title="Inserir"
        centered
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() =>
              form.validateFields().then((value) => postComandos(value))
            }
          >
            <Form.Item
              name="descricao"
              label="Descrição:"
              style={{ fontWeight: "500" }}
              rules={[{ required: true, message: "Este campo é obrigatório." }]}
            >
              <Input />
            </Form.Item>

            <div style={{ display: "flex", gap: 24 }}>
              <Form.Item
                style={{ width: "10%", fontWeight: "500" }}
                name="uf"
                label="UF:"
              >
                <Select options={options} />
              </Form.Item>

              <Form.Item
                style={{ width: "100%", fontWeight: "500" }}
                label="Sistema:"
                name="sistema_id"
              >
                <Select options={optionsSistemas} />
              </Form.Item>
            </div>

            <Form.Item
              name="texto_firebird"
              label="Texto Firebird"
              style={{ fontWeight: "500" }}
              rules={[{ required: true, message: "Este campo é obrigatório." }]}
            >
              <TextArea rows={19} />
            </Form.Item>

            <Form.Item
              name="em_teste"
              label="Em Teste:"
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch style={{ marginTop: -20 }} />
            </Form.Item>

            <Divider />

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  width: "26%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button onClick={close}>Cancelar</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  Confirmar
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
      <ModalStatusErro onCancel={() => setOpen(false)} visible={open} />
    </>
  );
};
