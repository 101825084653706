/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, Divider, Space, Result, Tag, Breadcrumb } from "antd";
import AtualizacaoRequest from "../Atualizacao/atualizacao";
import { InputForm } from "../components/inputForm/InputForm";
import { styles } from "./estilos";
import {
  DeleteOutlined,
  CopyOutlined,
  BulbOutlined,
  SendOutlined,
  RocketOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { ufs } from "../../../Constante/UFS";
import { ModalAtualizacao } from "../components/modalAtualizacao/ModalAtualizacao";
import SelAtualizacao from "../../../Selecao/Movimentacao/Sel_atualizacao";
import { ModalDetalhes } from "../components/modalDetalhes/ModalDetalhes";
import {
  BotãoExecutar,
  BotãoIncluir,
  BotãoSelecionar,
} from "../components/botões/botõesIncluirExecutar/Botoes";
import {
  CartoriosSelecionados,
  ListaCartoriosSelecionados,
} from "../components/cartorioSelecionados/CartoriosSelecionados";
import { FiltroCartorios } from "../components/filtroCartorios/FiltrosCartorios";
import { ModalExecutar } from "../components/modalExecutar/modalExecutar";
import { ModalError } from "../components/modalError";

export const AtualizacaoId = () => {
  const { atualizacaoId } = useParams();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalErro, setModalErro] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [modalExecutar, setModalExecutar] = useState(false);
  const [executeLoading, setExecuteLoading] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [isModalCartorioVisible, setIsModalCatorioVisible] = useState(false);

  const [cartorios, setCartorios] = useState([
    {
      atualizacao_id: null,
      atualizar_com_erro: false,
      cartorio_cidade: "",
      cartorio_id: null,
      data_fim: null,
      data_inicio: null,
      id: null,
      nome_cartorio: "",
      status: "",
      status_atualizado_helpsystem: "",
    },
  ]);
  const [cartoriosSelecionados, setCartoriosSelecionados] = useState([]);

  const [deleteId, setDeleteId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [totalRecords, setTotalRecords] = useState(0);

  const [filtros, setFiltros] = useState({ atualizacao_id: atualizacaoId });

  const [cartorioStatus, setCartorioStatus] = useState("");

  const [form] = Form.useForm();
  const [formLista] = Form.useForm();

  const [errorStatus, setErrorStatus] = useState(0);

  const formatarData = (date) => {
    const data = new Date(date);
    const dataFormatada =
      date === null ? "" : data.toLocaleString().replace(",", "");
    return dataFormatada;
  };

  function onSelecionarCartorio(valores) {
    if (!valores || valores.length === 0) {
      console.error("Nenhum cartório foi selecionado.");
      return;
    }

    const cartoriosSelecionadosIds = cartoriosSelecionados.map(
      (cartorio) => cartorio.id
    );

    const novosCartoriosSelecionados = valores.filter(
      (valor) => !cartoriosSelecionadosIds.includes(valor.id)
    );

    if (novosCartoriosSelecionados.length === 0) return;

    setIsModalCatorioVisible(false);
    setCartoriosSelecionados((prevCartorios) => [
      ...prevCartorios,
      ...novosCartoriosSelecionados,
    ]);
  }

  const optionsUf = ufs.map((uf) => {
    return {
      label: uf.nome,
      value: uf.sigla,
    };
  });

  const onDeleteCartorio = (cartorio) => {
    setCartoriosSelecionados((prevCartorios) =>
      prevCartorios.filter((item) => item.id !== cartorio.id)
    );
  };

  const montarAtualizacao = async () => {
    setLoading(true);

    try {
      const cartoriosSelecionadosIds = cartoriosSelecionados.map(
        (cartorio) => cartorio.id
      );

      const dados = {
        cartorios: cartoriosSelecionadosIds.map((id) => ({
          cartorio_id: id,
        })),
        atualizacao_id: atualizacaoId,
      };

      const response = await AtualizacaoRequest.postAtualizacaoCartorio(dados);
      if (response.status === 200 || response.status === 201) {
        setOpenModal(false);
        FetchRequest(1, 10, filtros);
        setCartoriosSelecionados([]);
        setLoading(false);
      }
    } catch (error) {
      setErrorMessage(
        error.response ? error.response.data.detail : "Erro desconhecido"
      );
      setLoading(false);
    }
  };

  async function onPesquisarClick(value) {
    setFiltros((prevState) => ({ ...prevState, ...value }));
    setOpen(false);
  }

  useEffect(() => {
    if (filtros) {
      FetchRequest(1, 10, filtros);
    }
  }, [filtros]);

  const columns = [
    {
      title: "Nome cartório",
      dataIndex: "nome_cartorio",
      key: "nome_cartorio",
    },
    { title: "Cidade", dataIndex: "cartorio_cidade", key: "nome_cartorio" },
    { title: "UF", dataIndex: "cartorio_uf", key: "cartorio_uf" },
    {
      title: "Data inicio",
      dataIndex: "data_inicio",
      key: "data_inicio",
      render: (data_inicio) => formatarData(data_inicio),
    },
    {
      title: "Data fim",
      dataIndex: "data_fim",
      key: "data_fim",
      render: (data_fim) => formatarData(data_fim),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        const statusIcons = {
          C: <BulbOutlined />,
          V: <SendOutlined />,
          I: <RocketOutlined />,
          O: <CheckCircleOutlined />,
          E: <CloseCircleOutlined />,
        };
        const statusTexts = {
          C: "Criada",
          V: "Enviada",
          I: "Iniciada",
          O: "Concluída",
          E: "Erro",
        };
        const statusIcon = statusIcons[status];
        const statusText = statusTexts[status];
        return (
          <text>
            {statusIcon} {statusText}
          </text>
        );
      },
      width: 120,
    },
    {
      title: "Atualizar com erro",
      dataIndex: "atualizar_com_erro",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      title: "Status do atualizador",
      dataIndex: "status_atualizado_helpsystem",
      render: (value) => (
        <Tag color={value === "INATIVO" ? "red" : "green"}>{value}</Tag>
      ),
    },
    {
      title: "Ações",
      render: (record) => (
        <Space>
          <CopyOutlined onClick={() => handleItemClick(record.id)} />
          {cartorioStatus !== "I" && (
            <DeleteOutlined onClick={() => onDeleteAtualizacao(record.id)} />
          )}
        </Space>
      ),
    },
  ];

  const onDeleteAtualizacao = (id) => {
    setDeleteId(id);
    setOpenModalDelete(true);
  };

  const ConfirmarDelete = async () => {
    setLoading(true);
    try {
      const response = await AtualizacaoRequest.deleteAtualizacaoCartorio(
        deleteId
      );
      setCartorios((prevAtualizacoes) =>
        prevAtualizacoes.filter((item) => item.id !== deleteId)
      );
      if (response.status === 204) {
        setShowResult(true);
        setLoading(false);
      }
    } catch (error) {
      setDeleteError(
        error.response ? error.response.data.detail : "Erro desconhecido"
      );
      setLoading(false);
    }
  };

  const FetchRequest = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await AtualizacaoRequest.getAtualizacaoCartorio(
        page,
        pageSize,
        params
      );
      if (response.status === 200) {
        const { results, count } = response.data;
        setCartorios(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const limpar = () => {
    setFiltros({ atualizacao_id: atualizacaoId });
    formLista.resetFields();
    setOpen(false);
  };

  const estadosDelete = () => {
    if (deleteError !== null) {
      return (
        <Result
          status="warning"
          title={deleteError}
          extra={
            <Button
              type="primary"
              onClick={() => {
                setOpenModalDelete(false);
                setDeleteError(null);
              }}
            >
              Fechar
            </Button>
          }
        />
      );
    }

    if (showResult) {
      return (
        <Result
          status="success"
          title="Cartório excluído da atualização com sucesso!"
          extra={[
            <Button
              type="primary"
              onClick={() => {
                setOpenModalDelete(false);
                setShowResult(false);
              }}
            >
              Voltar
            </Button>,
          ]}
        />
      );
    }

    return (
      <text style={{ fontWeight: "600" }}>
        Deseja excluir esse cartório da atualização?
      </text>
    );
  };

  const handleItemClick = (item) => {
    setSelectedId(item);
    setModalDetails(true);
  };

  const ExecutarAtualizacao = async () => {
    setExecuteLoading(true);
    try {
      const response = await AtualizacaoRequest.postExecutarAtualizacao(
        atualizacaoId
      );
      if (response.status === 201) {
        setModalExecutar(false);
        setExecuteLoading(false);
      }
    } catch (error) {
      setModalExecutar(false);
      setModalErro(true);
      setExecuteLoading(false);
      const { status } = error.response;
      setErrorStatus(status.toString());
    }
  };

  useEffect(() => {
    FetchRequest(1, 10, filtros);
  }, [modalExecutar]);

  const carregarAtualizacao = async () => {
    setLoading(true);
    try {
      const response = await AtualizacaoRequest.getAtualizacaoId(atualizacaoId);

      const { data } = response;

      if (response.status === 200 || response.status === 201) {
        const formatted = {
          ...data,
          data_cadastro: data.data_cadastro && formatarData(data.data_cadastro),
          data_inicio: data.data_inicio && formatarData(data.data_inicio),
          data_fim: data.data_fim && formatarData(data.data_fim),
        };

        const statusMap = {
          C: "Criada",
          I: "Iniciada",
          E: "Executada",
        };

        formatted.status = statusMap[data.status] || "";

        setCartorioStatus(data.status);

        form.setFieldsValue(formatted);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    carregarAtualizacao();
  }, [modalExecutar]);

  useEffect(() => {
    setShowResult(false);
  }, [openModalDelete]);

  const tags = {
    nome_cartorio: "Nome cartório",
    status: "Status",
  };

  const onRemoveFiltros = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagsColor = () => {
            if (key === "nome_cartorio") return "lime";
            if (key === "status") return "volcano";
          };

          if (key === "atualizacao_id") {
            return null;
          }

          if (value === "") {
            return null;
          }

          const dictionaryStatus = {
            C: "Criada",
            V: "Enviada",
            I: "Iniciada",
            O: "Concluida",
            E: "Erro",
          };

          if (key === "status") {
            return (
              <Tag
                closable
                onClose={() => onRemoveFiltros(key)}
                key={key}
                color={tagsColor(key)}
              >
                <text style={{ padding: 10 }}>
                  {tags[key] + ": " + dictionaryStatus[value]}
                </text>
              </Tag>
            );
          }

          return (
            <Tag
              closable
              onClose={() => onRemoveFiltros(key)}
              key={key}
              color={tagsColor(key)}
            >
              <text style={{ padding: 10 }}>{tags[key] + ": " + value}</text>
            </Tag>
          );
        })}
    </>
  );

  const getSpreadcheet = async () => {
    try {
      const response = await AtualizacaoRequest.getGerarPlanilha(atualizacaoId);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(response.data);

        const anchorElement = document.createElement("a");

        anchorElement.href = url;
        anchorElement.download = `atualizacao-${atualizacaoId}`;

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <Breadcrumb style={{ padding: 10, fontSize: 14, fontWeight: "500" }}>
        <Breadcrumb.Item href="/Atualizacao">Atualizações</Breadcrumb.Item>
        <Breadcrumb.Item>Detalhes</Breadcrumb.Item>
      </Breadcrumb>

      <div>
        <Form form={form} preserve={false} layout="vertical">
          <div style={{ padding: "5px 0px 5px 5px" }}>
            <div style={{ display: "flex" }}>
              <InputForm name="nome_usuario" label="Nome usuário" />
              <InputForm name="data_cadastro" label="Data cadastro" />
              <InputForm name="data_inicio" label="Data inicio" />
              <InputForm name="data_fim" label="Data fim" />
              <InputForm name="status" label="Status" />
            </div>
            <div style={styles.containerButtons}>
              <BotãoSelecionar
                onClick={() => setIsModalCatorioVisible(true)}
                disabled={cartorioStatus === "I" ? true : false}
              />
              <BotãoIncluir
                onClick={() => setOpenModal(true)}
                disabled={
                  cartoriosSelecionados.length === 0 ||
                  (cartorioStatus !== "C" && cartoriosSelecionados.length === 0)
                }
              />
              <BotãoExecutar
                disabled={
                  cartorioStatus !== "C" ||
                  cartorios.length === 0 ||
                  (cartorios.length === 0 && cartorioStatus !== "C")
                    ? true
                    : false
                }
                onClick={() => setModalExecutar(true)}
              />
            </div>
          </div>
        </Form>

        {cartoriosSelecionados.length > 0 && (
          <div>
            <CartoriosSelecionados />
            {cartoriosSelecionados.map((item) => (
              <ListaCartoriosSelecionados
                nomeCartorio={item.nome_cartorio}
                onClick={() => onDeleteCartorio(item)}
              />
            ))}
          </div>
        )}

        <Divider />

        <div>
          <FiltroCartorios
            form={formLista}
            onClick={() => setOpen(true)}
            onClose={() => setOpen(false)}
            visible={open}
            onFinish={onPesquisarClick}
            limpar={limpar}
            loading={loading}
            columns={columns}
            cartorios={cartorios}
            pagination={{
              total: totalRecords,
              showTotal: (total) => (
                <span
                  style={{
                    fontWeight: "500",
                    position: "absolute",
                    left: "4%",
                  }}
                >
                  Quantidade: {total}
                </span>
              ),
              onChange: (page, pageSize) => {
                FetchRequest(page, pageSize, filtros);
              },
            }}
            openModalDelete={openModalDelete}
            onCancel={() => setOpenModalDelete(false)}
            onOk={ConfirmarDelete}
            footer={
              deleteError || showResult
                ? null
                : [
                    <Button onClick={() => setOpenModalDelete(false)}>
                      Cancelar
                    </Button>,
                    <Button type="primary" onClick={ConfirmarDelete}>
                      Confirmar
                    </Button>,
                  ]
            }
            estadosDelete={estadosDelete()}
            title={getTags}
            onExport={getSpreadcheet}
          />

          <ModalDetalhes
            visible={modalDetails}
            onCancel={() => setModalDetails(false)}
            selectedItem={selectedId}
          />
        </div>
      </div>

      <SelAtualizacao
        onSelecionado={(valor) => {
          onSelecionarCartorio(valor);
        }}
        visible={isModalCartorioVisible}
        onCancel={() => setIsModalCatorioVisible(false)}
        optionsUf={optionsUf}
      />

      <ModalAtualizacao
        visible={openModal}
        onClose={() => {
          setOpenModal(false);
          setErrorMessage(null);
        }}
        onOk={montarAtualizacao}
        erro={errorMessage}
        erroMessage={errorMessage}
      />

      <ModalExecutar
        visible={modalExecutar}
        onCancel={() => setModalExecutar(false)}
        onOk={() => {
          if (cartorios) ExecutarAtualizacao();
        }}
        loading={executeLoading}
      />

      <ModalError
        title={errorStatus}
        subtitle="Desculpe, algo deu errado."
        onCancel={() => {
          setModalErro(false);
          setErrorStatus(0);
        }}
        visible={modalErro}
      />
    </div>
  );
};
