import { useEffect, useState, React } from "react";
import SeloGrupoRequest from "./SeloGrupoRequest";
import { tipoCartorio } from "../../Constante/TipoCartorio";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Radio,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import {
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import CadastroSeloGrupo from "./CadastroSeloGrupo";
import sistemaRequest from "../../Utils/sistemaRequest";
import { ufs } from "../../Constante/UFS";
import { ModalDelete } from "./modalDelete";
import { colors } from "../../Utils/temas";

const { TabPane } = Tabs;

const SeloGrupo = () => {
  const [seloGrupo, setSeloGrupo] = useState([]);
  const [sistemas, setSistemas] = useState([{ id: 0, nome_sistema: "" }]);
  const [filtros, setFiltros] = useState({});

  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [id, setId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [idDelete, setIdDelete] = useState(null);

  const [form_pesquisa] = Form.useForm();

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await SeloGrupoRequest.getListaSeloGrupo(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      setSeloGrupo(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  async function CarregarSistema() {
    const response = await sistemaRequest.getSistemas();
    if (response.status === 200) {
      const { results } = response.data;
      const notSystems = [
        "ADMINISTRATIVO",
        "CAIXA",
        "FINANCEIRO",
        "LIVRO DIÁRIO",
      ];

      const sistemasFiltrados = results.filter(
        (item) => !notSystems.includes(item.nome_sistema)
      );
      setSistemas(sistemasFiltrados);
      setFiltros({ sistema_id: sistemasFiltrados[0].id });
    }
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  function onInserirClick() {
    showModal();
  }

  useEffect(() => {
    CarregarSistema();
  }, []);

  useEffect(() => {
    if (filtros.sistema_id) {
      FetchRecords(1, 10, filtros);
    }
  }, [filtros]);

  async function onEditSeloGrupo(id) {
    setIsModalVisible(true);
    setId(id);
  }

  function onDeletarSeloGrupo(id) {
    setIdDelete(id);
    setOpenDelete(true);
  }

  async function onPesquisarClick(value) {
    const newValue = { ...value, sistema_id: filtros.sistema_id };
    setFiltros(newValue);
    FetchRecords(1, 10, newValue);
    setDrawerVisible(false);
  }

  const Limpar = () => {
    setFiltros((prevState) => ({
      situacao: undefined,
      uf: undefined,
      sistema_id: prevState.sistema_id,
    }));
    form_pesquisa.resetFields();
    FetchRecords(1, 10, { sistema_id: filtros.sistema_id });
    setDrawerVisible(false);
  };

  const optionsTipoCartorio = tipoCartorio.map((tipo_cartorio) => {
    return {
      label: tipo_cartorio.descricao,
      value: tipo_cartorio.tipo,
    };
  });

  const optionsSistema = sistemas.map((sistema) => {
    return {
      label: sistema.nome_sistema,
      value: sistema.id,
    };
  });

  const optionsUf = ufs.map((uf) => {
    return {
      label: uf.sigla,
      value: uf.sigla,
    };
  });

  const columns = [
    {
      title: "Decrição",
      dataIndex: "descricao",
    },
    {
      title: "Descrição Completa",
      dataIndex: "descricao_completa",
    },
    {
      title: "Tipo Cartorio",
      dataIndex: "tipo_cartorio",
      render: (tipo_cartorio) => {
        let tipo_cartorio_filtrado = optionsTipoCartorio.find(
          (tipoCartorio) => tipoCartorio.value === tipo_cartorio
        );
        return tipo_cartorio_filtrado?.label;
      },
    },
    {
      title: "Sistema",
      dataIndex: "sistema_descricao",
    },
    {
      title: "Situação",
      dataIndex: "situacao",
      key: "situacao",
      render: (situacao) => {
        let situacao_descricao = "";
        let color = "";

        if (situacao === "A") {
          situacao_descricao = "Ativo";
          color = "green";
        } else {
          situacao_descricao = "Inativo";
          color = "red";
        }
        return <Tag color={color}> {situacao_descricao} </Tag>;
      },
    },
    {
      title: "Número",
      dataIndex: "numero",
    },
    {
      title: "Pagina acresce",
      dataIndex: "pagina_acresce",
      render: (pagina_acresce) => {
        let pagina_acresce_descricao = "";
        let color = "";

        if (pagina_acresce === true) {
          pagina_acresce_descricao = "Sim";
          color = "green";
        } else {
          pagina_acresce_descricao = "Não";
          color = "red";
        }
        return <Tag color={color}> {pagina_acresce_descricao} </Tag>;
      },
    },
    {
      title: "Controle Automatico",
      dataIndex: "controle_automatico",
      render: (controle_automatico) => {
        let controle_automatico_descricao = "";
        let color = "";

        if (controle_automatico === true) {
          controle_automatico_descricao = "Sim";
          color = "green";
        } else {
          controle_automatico_descricao = "Não";
          color = "red";
        }
        return <Tag color={color}> {controle_automatico_descricao} </Tag>;
      },
    },
    {
      title: "Estoque minimo",
      dataIndex: "estoque_minimo",
    },
    {
      title: "UF",
      dataIndex: "uf",
    },
    {
      title: "Ações",
      key: "acoes",
      render: (record) => (
        <Space>
          <Tooltip title="Editar">
            <EditOutlined onClick={(_) => onEditSeloGrupo(record.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={(_) => onDeletarSeloGrupo(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const tags = {
    sistema_id: "Sistema",
    situacao: "Situação",
    uf: "UF",
  };

  const tagsValue = {
    A: "Ativo",
    F: "Inativo",
    GO: "GO",
    AL: "AL",
    CE: "CE",
    PA: "PA",
  };

  const onRemoverFiltros = (tag) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[tag];

    setFiltros(novosFiltros);
    FetchRecords(1, 10, novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagColors = () => {
            if (value === "A") {
              return "green";
            } else if (value === "F") {
              return "red";
            } else if (key === "uf") {
              return "volcano";
            }
          };

          if (key === "sistema_id") {
            return null;
          }

          return (
            <Tag
              closable
              color={tagColors(value)}
              onClose={() => onRemoverFiltros(key)}
              key={key}
            >
              {tags[key] + ": " + tagsValue[value]}
            </Tag>
          );
        })}
    </>
  );

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between", padding: 5 }}
      >
        <Button
          onClick={onInserirClick}
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          className="botao"
        >
          <PlusOutlined />
          Inserir
        </Button>

        <Button
          onClick={() =>
            setDrawerVisible((prevDrawerVisible) => !prevDrawerVisible)
          }
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          className="botao"
        >
          <FilterOutlined />
          Filtros
        </Button>
        <Drawer
          width={380}
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
        >
          <Form
            form={form_pesquisa}
            layout="vertical"
            onFinish={onPesquisarClick}
            preserve={true}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item name="situacao" label="Situação">
                <Radio.Group>
                  <Radio value={"A"}>Ativo</Radio>
                  <Radio value={"F"}>Inativo</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item name="uf" label="UF" style={{ width: "20%" }}>
                <Select options={optionsUf} />
              </Form.Item>
            </div>

            <Divider style={{ background: "#DCDCDC" }} />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Col>
                <Button
                  htmlType="submit"
                  style={{
                    background: colors.secondary,
                    color: "white",
                    fontWeight: "500",
                  }}
                  className="botao"
                >
                  <KeyOutlined />
                  Pesquisar
                </Button>
              </Col>

              <Col>
                <Button onClick={Limpar}>
                  <CloseOutlined />
                  Limpar
                </Button>
              </Col>
            </div>
          </Form>
        </Drawer>
      </div>

      <Tabs
        type="card"
        size="large"
        defaultActiveKey={sistemas[0].id}
        onChange={(e) => {
          setFiltros((prevState) => ({ ...prevState, sistema_id: e }));
        }}
      >
        {sistemas.map((item) => (
          <TabPane tab={item.nome_sistema} key={item.id}>
            <Table
              loading={loading}
              columns={columns}
              rowKey={"id"}
              dataSource={seloGrupo}
              pagination={{
                total: totalRecords,
                showTotal: (total) => (
                  <span
                    style={{ fontWeight: "500", position: "absolute", left: "4%" }}
                  >
                    Quantidade: {total}
                  </span>
                ),
                onChange: (page, pagaSize) => {
                  setCurrentPage(page);
                  FetchRecords(page, pagaSize, filtros);
                },
              }}
              title={getTags}
              style={{ marginTop: -16 }}
              scroll={{ x: true }}
            />
          </TabPane>
        ))}
      </Tabs>

      <CadastroSeloGrupo
        optionsSistema={optionsSistema}
        optionsTipoCartorio={optionsTipoCartorio}
        optionsUf={optionsUf}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setId(null);
        }}
        estadoSeloGrupo={() => FetchRecords(currentPage, 10, filtros)}
        id={id}
      />

      <ModalDelete
        onCancel={() => setOpenDelete(false)}
        visible={openDelete}
        id={idDelete}
        estadoSeloGrupo={setSeloGrupo}
      />
    </div>
  );
};

export default SeloGrupo;
