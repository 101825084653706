import { Button, Space, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { colors } from "../../../Utils/temas";
import sistemaRequest from "../../../Utils/sistemaRequest";
import { ModalInserir } from "../componentes/modais/modalInserir";
import { requestReport } from "../request";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ModalDeletar } from "../componentes/modais/modalDeletar";

const { TabPane } = Tabs;

export const RelatorioGrupo = () => {
  const [sistemas, setSistemas] = useState([{ id: 0, nome_sistema: "" }]);
  const [gruposRelatorios, setGruposRelatorios] = useState({
    count: 0,
    results: [
      { id: 0, descricao: "", sistema_id: 0, sistema__nome_sistema: "" },
    ],
  });

  const [idDelete, setIdDelete] = useState(null);
  const [sistemaId, setSistemaId] = useState(null);
  const [relatorioGrupoId, setRelatorioGrupoId] = useState(null);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    getSistemas();
  }, []);

  useEffect(() => {
    if (sistemaId) {
      getRelatoriosGrupo({ sistema_id: sistemaId });
    }
  }, [sistemaId]);

  const getSistemas = async () => {
    try {
      const response = await sistemaRequest.getSistemas();
      if (response.status === 200) {
        const { results } = response.data;

        setSistemas(results);
        setSistemaId(results[0].id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRelatoriosGrupo = async (params) => {
    setLoading(true);
    try {
      const response = await requestReport.getGroupReport(params);
      if (response.status === 200) {
        const { results, count } = response.data;
        setGruposRelatorios({ count: count, results: results });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    { title: "Descrição", dataIndex: "descricao" },
    { title: "Sistema", dataIndex: "sistema__nome_sistema" },
    {
      title: "Ações",
      render: (render) => (
        <Space size="middle">
          <EditOutlined onClick={() => handleEdit(render.id)} />
          <DeleteOutlined onClick={() => handleDelete(render.id)} />
        </Space>
      ),
    },
  ];

  const handleEdit = (value) => {
    setRelatorioGrupoId(value);
    setOpen(true);
  };

  const handleDelete = (value) => {
    setIdDelete(value);
    setOpenDelete(true);
  };

  return (
    <>
      <div style={{ padding: 10 }}>
        <Button
          icon={<PlusOutlined />}
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          className="botao"
          onClick={() => setOpen(true)}
        >
          Inserir
        </Button>
      </div>

      <Tabs type="card" size="large" onChange={(e) => setSistemaId(e)}>
        {sistemas &&
          sistemas.length > 0 &&
          sistemas.map((item) => (
            <TabPane
              tab={item.nome_sistema}
              key={item.id}
              style={{ marginTop: -18 }}
            >
              <Table
                columns={columns}
                dataSource={gruposRelatorios.count}
                loading={loading}
                scroll={{ x: true }}
              />
              <span
                style={{
                  fontWeight: "500",
                  marginLeft: 18,
                }}
              >
                Quantidade: {gruposRelatorios.count}
              </span>
            </TabPane>
          ))}
      </Tabs>

      <ModalInserir
        visible={open}
        onCancel={() => {
          setOpen(false);
          setRelatorioGrupoId(null);
        }}
        sistemas={sistemas}
        estadoRelatorio={setGruposRelatorios}
        value={sistemaId}
        id={relatorioGrupoId}
      />

      <ModalDeletar
        visible={openDelete}
        onCancel={() => setOpenDelete(false)}
        id={idDelete}
        estadoRelatorioGrupo={() =>
          getRelatoriosGrupo({ sistema_id: sistemaId })
        }
      />
    </>
  );
};
