/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  FilterOutlined,
  CloseOutlined,
  KeyOutlined,
  DeleteOutlined,
  CopyOutlined,
  BulbOutlined,
  RocketOutlined,
  CheckCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Table,
  Button,
  Drawer,
  Space,
  Modal,
  Result,
  Divider,
  Tag,
  Tooltip,
  Select,
  Input,
} from "antd";
import AtualizacaoRequest from "./atualizacao";
import { styles } from "./estilos";
import { ModalNovaAtualizacao } from "../components/modal/Modal";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../Utils/temas";
import { useUserContext } from "../../../contexts/usersContext";
import SelCartorio from "../../../Selecao/Cartorio/Sel_Carotorio";

const { Search } = Input;

export const Atualizacao = () => {
  const history = useNavigate();

  const atualizacoesRef = useRef([]);

  const [atualizacoes, setAtualizacoes] = useState([]);
  const [usuarios, setUsuarios] = useState([{ id: 0, nome: "" }]);

  const [open, setOpen] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [openSelCart, setOpenSelCart] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [deleteId, setDeleteId] = useState(null);
  const [deleteError, setDeleteErro] = useState(null);
  const [redirectId, setRedirectId] = useState(null);

  const [totalRecords, setTotalRecords] = useState(0);

  const { currentUser } = useUserContext();

  const filtersFunc = () => {
    const savedFilters = localStorage.getItem("atualizacao_filtros");

    if (savedFilters) return JSON.parse(savedFilters);
    return {};
  };

  const [filtros, setFiltros] = useState(filtersFunc);
  const [filtrosDraw, setFiltrosDraw] = useState({
    usuario_id: currentUser.id,
  });

  const formatarData = (data) => {
    const date = new Date(data);
    const dataFormatada = data === null ? "" : date.toLocaleString().split(",");
    return dataFormatada;
  };

  const columns = [
    { title: "Usuário", dataIndex: "nome_usuario" },
    {
      title: "Data cadastro",
      dataIndex: "data_cadastro",
      key: "data_cadastro",
      render: (data_cadastro) => formatarData(data_cadastro),
    },
    {
      title: "Data inicio",
      dataIndex: "data_inicio",
      key: "data_inicio",
      render: (data_inicio) => formatarData(data_inicio),
    },
    {
      title: "Data fim",
      dataIndex: "data_fim",
      key: "data_fim",
      render: (data_fim) => formatarData(data_fim),
    },
    {
      title: "Status",
      render: (_, record) => {
        const statusMap = {
          C: (
            <text>
              <BulbOutlined /> Criada
            </text>
          ),
          I: (
            <text>
              <RocketOutlined /> Iniciada
            </text>
          ),
          E: (
            <div>
              <CheckCircleOutlined /> Executada
            </div>
          ),
        };

        return <div>{statusMap[record.status]}</div>;
      },
      key: "status",
    },
    {
      title: "Erro",
      dataIndex: "atualizacao_com_erro",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      title: "Ações",
      render: (record) => (
        <Space>
          <Tooltip title="Detalhes">
            <CopyOutlined onClick={(_) => onDetalhesAtualizacao(record.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={() => onDeletarAtualizacao(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onDeletarAtualizacao = async (id) => {
    setDeleteId(id);
    setOpenModalDelete(true);
  };

  const ConfirmarDelete = async () => {
    setDeleting(true);
    setDeleteErro(null);
    setConfirmLoading(true);
    try {
      const response = await AtualizacaoRequest.deleteAtualizacao(deleteId);

      if (response.status === 204) {
        setShowResult(true);
        setAtualizacoes((prevAtualizacoes) =>
          prevAtualizacoes.filter((item) => item.id !== deleteId)
        );
      }
    } catch (error) {
      console.error(error);
      setDeleteErro(
        error.response ? error.response.data.detail : "Erro desconhecido"
      );
    } finally {
      setDeleting(false);
      setConfirmLoading(false);
    }
  };

  const FetchRequest = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await AtualizacaoRequest.getAtualizacao(
        page,
        pageSize,
        params
      );
      if (response.status === 200) {
        setAtualizacoes(response.data.results);
        atualizacoesRef.current = response.data.results;
        setTotalRecords(response.data.count);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const enviarAtualizacao = async () => {
    setLoading(true);
    try {
      const dataAtual = new Date().toISOString();
      const dados = {
        data_cadastro: dataAtual,
        status: "C",
      };
      const response = await AtualizacaoRequest.postAtualizacao(dados);

      if (response.status === 200 || response.status === 201) {
        const responseData = response.data;
        setAtualizacoes((prevAtualizacoes) => [
          responseData,
          ...prevAtualizacoes,
        ]);
        setSuccess(true);
        setRedirectId(responseData.id);
      } else {
        throw new Error("A requisição falhou");
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    listarUsuarios();
  }, []);

  useEffect(() => {
    if (filtros) {
      FetchRequest(1, 10, filtros);
      getFilters();
    }
  }, [filtros]);

  useEffect(() => {
    setSuccess(false);
  }, [openModal]);

  const onDetalhesAtualizacao = (id) => {
    history(`/Atualizacao/${id}`);
  };

  const limpar = () => {
    setFiltros({});
    setFiltrosDraw({ usuario_id: currentUser.id });
    FetchRequest(1, 10, {});
    setOpen(false);
  };

  useEffect(() => {
    setDeleteErro(null);
  }, [openModalDelete]);

  const estadosDelete = () => {
    if (deleteError !== null) {
      return (
        <Result
          status="warning"
          title={deleteError}
          extra={
            <Button type="primary" onClick={() => setOpenModalDelete(false)}>
              Fechar
            </Button>
          }
        />
      );
    } else if (deleteError === null) {
      return showResult ? (
        <Result
          status="success"
          title="Atualização excluída com sucesso!"
          extra={[
            <Button type="primary" onClick={() => setOpenModalDelete(false)}>
              Voltar
            </Button>,
          ]}
        />
      ) : (
        <text style={{ fontWeight: "600" }}>
          Deseja excluir essa atualização?
        </text>
      );
    }
  };

  useEffect(() => {
    setShowResult(false);
  }, [openModalDelete]);

  const tags = {
    usuario_id: "Nome usuário",
    status: "Status",
    cartorio_id: "Cartório",
  };

  const onRemoveTags = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
    FetchRequest(1, 10, novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagsColors = () => {
            if (key === "usuario_id") return "gold";
            if (key === "status") return "volcano";
            if (key === "cartorio_id") return "orange";
          };

          const filtragemUsuario = usuarios.filter((item) => item.id === value);

          if (key === "usuario_id") {
            return (
              <Tag
                closable
                onClose={() => onRemoveTags(key)}
                color={tagsColors(key)}
                key={key}
              >
                <text style={{ padding: 10 }}>
                  Usuário:{" "}
                  {filtragemUsuario &&
                    filtragemUsuario.length > 0 &&
                    filtragemUsuario[0].nome}
                </text>
              </Tag>
            );
          }

          if (key === "status") {
            return (
              <Tag
                closable
                onClose={() => onRemoveTags(key)}
                color={tagsColors(key)}
                key={key}
              >
                <text style={{ padding: 10 }}>
                  {tags[key] + ": " + dictionaryStatus[value]}
                </text>
              </Tag>
            );
          }

          if (key === "nome_cartorio") {
            return null;
          }

          if (key === "cartorio_id") {
            return (
              <Tag
                closable
                onClose={() => onRemoveTags(key)}
                color={tagsColors(key)}
                key={key}
              >
                <text style={{ padding: 10 }}>
                  {tags[key] + ": " + filtros.nome_cartorio}
                </text>
              </Tag>
            );
          }

          return (
            <Tag
              closable
              onClose={() => onRemoveTags(key)}
              color={tagsColors(key)}
              key={key}
            >
              <text style={{ padding: 10 }}>{tags[key] + ": " + value}</text>
            </Tag>
          );
        })}
    </>
  );

  const dictionaryStatus = {
    C: "Criada",
    I: "Iniciada",
    E: "Executada",
  };

  const listarUsuarios = async () => {
    try {
      const response = await AtualizacaoRequest.getListarUsuarios();
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setUsuarios(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const optionsStatus = [
    { label: "Criada", value: "C" },
    { label: "Executada", value: "E" },
    { label: "Iniciada", value: "I" },
  ];

  const getFilters = () => {
    localStorage.setItem("atualizacao_filtros", JSON.stringify(filtros));
  };

  return (
    <div style={{ height: "100%" }}>
      <div>
        <div style={styles.containerButtons}>
          <Button
            onClick={() => setOpenModal(true)}
            style={{
              background: colors.secondary,
              fontWeight: "500",
              color: "white",
            }}
            className="botao"
          >
            <PlusOutlined />
            Inserir
          </Button>

          <Button
            onClick={() => setOpen(true)}
            style={{
              background: colors.secondary,
              color: "white",
            }}
            className="botao"
          >
            <FilterOutlined />
          </Button>
        </div>
        <Table
          loading={loading || deleting}
          columns={columns}
          dataSource={atualizacoes.filter((item) => {
            if (
              filtros.usuario_nome &&
              !item.nome_usuario.includes(filtros.usuario_nome)
            ) {
              return false;
            }

            return true;
          })}
          pagination={{
            total: totalRecords,
            showTotal: (total) => (
              <span
                style={{ fontWeight: "500", position: "absolute", left: "4%" }}
              >
                Quantidade: {total}
              </span>
            ),
            onChange: (page, pageSize) => {
              FetchRequest(page, pageSize, filtros);
            },
          }}
          title={getTags}
          scroll={{ x: true }}
        />

        <Drawer
          title="Filtro"
          placement="right"
          onClose={() => setOpen(false)}
          visible={open}
        >
          <div>
            <SelCartorio
              visible={openSelCart}
              onCancel={() => setOpenSelCart(false)}
              onSelecionado={(e) => {
                setFiltrosDraw((prevState) => ({
                  ...prevState,
                  cartorio_id: e.id,
                  nome_cartorio: e.nome_cartorio,
                }));
                setOpenSelCart(false);
              }}
            />
            <text style={{ fontWeight: "500" }}>Nome usuário:</text>
            <Select
              showSearch
              options={usuarios?.map((item) => ({
                label: item.nome,
                value: item.id,
              }))}
              filterOption={(input, option) =>
                (option.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              onChange={(e) =>
                setFiltrosDraw((prevState) => ({
                  ...prevState,
                  usuario_id: e,
                }))
              }
              style={{ width: "100%" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            <text style={{ fontWeight: "500" }}>Status:</text>
            <Select
              options={optionsStatus}
              onChange={(e) =>
                setFiltrosDraw((prevState) => ({ ...prevState, status: e }))
              }
            />
          </div>

          <div style={{ marginTop: 10 }}>
            <text style={{ fontWeight: "500" }}>Cartorio:</text>
            <Search
              onSearch={() => setOpenSelCart(true)}
              value={filtrosDraw.nome_cartorio}
            />
          </div>

          <Divider style={{ background: "#DCDCDC" }} />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              className="botao"
              onClick={() => {
                setFiltros(filtrosDraw);
                setOpen(false);
              }}
            >
              <KeyOutlined />
              Pesquisar
            </Button>

            <Button onClick={limpar}>
              <CloseOutlined />
              Limpar
            </Button>
          </div>
        </Drawer>
      </div>

      <ModalNovaAtualizacao
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        enviar={enviarAtualizacao}
        success={sucess}
        onClick={() => {
          if (redirectId) {
            history(`/Atualizacao/${redirectId}`);
          }
        }}
      />
      <Modal
        visible={openModalDelete}
        onCancel={() => setOpenModalDelete(false)}
        footer={
          deleteError || showResult
            ? null
            : [
                <Button onClick={() => setOpenModalDelete(false)}>
                  Cancelar
                </Button>,
                <Button
                  type="primary"
                  onClick={ConfirmarDelete}
                  loading={confirmLoading}
                >
                  Confirmar
                </Button>,
              ]
        }
      >
        {estadosDelete()}
      </Modal>
    </div>
  );
};
