import React, { useEffect, useState } from "react";
import sistemaRequest from "../../Utils/sistemaRequest";
import { Button, Skeleton, Space, Table, Tabs } from "antd";
import { requestReport } from "./request";
import { colors } from "../../Utils/temas";
import {
  PlusOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { ModalInserirRelatorios } from "./componentes/modais/modalInserirRelatorios";
import { ModalDeletarRelatorio } from "./componentes/modais/modalDeletarRelatorio";

const { TabPane } = Tabs;

export const Relatorios = () => {
  const [sistemas, setSistemas] = useState([{ id: 0, nome_sistema: "" }]);
  const [relatorioGrupo, setRelatorioGrupo] = useState([
    { id: 0, descricao: "", sistema_id: 0, sistema__nome_sistema: "" },
  ]);

  const [relatorios, setRelatorios] = useState({
    count: 0,
    results: [
      {
        id: 0,
        descricao: "",
        link_arquivo: "",
        grupo_relatorio_id: 0,
        grupo_relatorio__descricao: "",
      },
    ],
  });

  const [id, setId] = useState(null);
  const [sistemaId, setSistemaId] = useState(null);
  const [idRelatorio, setIdRelatorio] = useState({ descricao: "", id: null });

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);

  useEffect(() => {
    getSistemas();
  }, []);

  useEffect(() => {
    if (sistemaId) {
      getRelatorioGrupos({ sistema_id: sistemaId });
    }
  }, [sistemaId]);

  useEffect(() => {
    getRelatorios(1, 10, { grupo_relatorio_id: idRelatorio.id });
  }, [idRelatorio.id, sistemaId]);

  const getSistemas = async () => {
    try {
      const response = await sistemaRequest.getSistemas();
      if (response.status === 200) {
        const { results } = response.data;
        setSistemas(results);
        setSistemaId(results[0].id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRelatorioGrupos = async (params) => {
    setLoading(true);
    try {
      const response = await requestReport.getGroupReport(params);
      if (response.status === 200) {
        const { results } = response.data;
        setRelatorioGrupo(results);
        setLoading(false);
        setIdRelatorio({ descricao: results[0].descricao, id: results[0].id });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getRelatorios = async (page, pageSize, params) => {
    setLoadingReport(true);
    try {
      const response = await requestReport.getReport(page, pageSize, params);
      if (response.status === 200) {
        const { results, count } = response.data;
        setRelatorios({ count: count, results: results });
        setLoadingReport(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReport(false);
    }
  };

  const columns = [
    { title: "Descrição", dataIndex: "descricao" },
    { title: "Grupo", dataIndex: "grupo_relatorio__descricao" },
    {
      title: "Ações",
      render: (values) => (
        <Space size="middle">
          <DeleteOutlined onClick={() => handleDelete(values.id)} />
          <DownloadOutlined
            onClick={() => baixarArquivo(values.link_arquivo)}
          />
        </Space>
      ),
    },
  ];

  const handleDelete = (value) => {
    setId(value);
    setOpenDelete(true);
  };

  const baixarArquivo = (link) => {
    const element = document.createElement("a");
    element.href = link;
    element.click();
  };

  return (
    <>
      <div style={{ padding: 10 }}>
        <Button
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
          onClick={() => setOpen(true)}
        >
          <PlusOutlined />
          Inserir
        </Button>
      </div>
      <Tabs
        type="card"
        size="large"
        onChange={(e) => {
          setSistemaId(e);
          setIdRelatorio({ descricao: undefined, id: null });
        }}
      >
        {sistemas.map((item) => (
          <TabPane
            key={item.id}
            tab={item.nome_sistema}
            style={{ padding: "0px 10px 0px 10px" }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "20%" }}>
                {loading ? (
                  <Skeleton />
                ) : (
                  <>
                    {relatorioGrupo.map((item) => (
                      <div
                        key={item.id}
                        style={{
                          border: "1px solid",
                          padding: 4,
                          borderRadius: 10,
                          marginBottom: 10,
                          cursor: "pointer",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          width: "100%",
                          boxShadow:
                            idRelatorio.id === item.id
                              ? "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                              : null,
                          borderColor:
                            idRelatorio.id === item.id
                              ? colors.secondary
                              : null,
                          background:
                            idRelatorio.id === item.id
                              ? colors.secondary
                              : null,
                          color: idRelatorio.id === item.id ? "white" : "black",
                        }}
                        onClick={() =>
                          setIdRelatorio((prevState) => ({
                            ...prevState,
                            id: item.id,
                          }))
                        }
                      >
                        <text
                          style={{
                            fontWeight: "500",
                            fontSize: 18,
                          }}
                        >
                          {item.descricao}
                        </text>
                      </div>
                    ))}
                  </>
                )}
              </div>

              <Table
                columns={columns}
                dataSource={idRelatorio.id ? relatorios.results : []}
                loading={loadingReport}
                scroll={{ x: true }}
                style={{ width: "100%", marginLeft: 40 }}
                pagination={{
                  total: relatorios.count,
                  onChange: (page, pageSize) =>
                    getRelatorios(page, pageSize, {
                      grupo_relatorio_id: idRelatorio.id,
                    }),
                  showTotal: (total) =>
                    idRelatorio.id && (
                      <span
                        style={{
                          fontWeight: "500",
                          marginLeft: 18,
                          position: "absolute",
                          left: 0,
                        }}
                      >
                        Quantidade: {total}
                      </span>
                    ),
                }}
              />
            </div>
          </TabPane>
        ))}
      </Tabs>

      <ModalInserirRelatorios
        visible={open}
        onCancel={() => setOpen(false)}
        id={idRelatorio.id}
        estadoRelatorio={() =>
          getRelatorios({ grupo_relatorio_id: idRelatorio.id })
        }
      />
      <ModalDeletarRelatorio
        onCancel={() => setOpenDelete(false)}
        visible={openDelete}
        id={id}
        estadoRelatorio={() =>
          getRelatorios({ grupo_relatorio_id: idRelatorio.id })
        }
      />
    </>
  );
};
