/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Form, Input, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import TabelaRequest from "../../../tabelaRequest";
import { colors } from "../../../../../Utils/temas";

export const SelectCampos = ({ openModal, onCancel, id, onSelecionado }) => {
  const [campo, setCampo] = useState([]);

  const [tabelaNome, setTabelaNome] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);

  const [loading, setLoading] = useState(false);

  const [campoSelecionado, setCampoSelecionado] = useState({});
  const [filtros, setFiltros] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    getCampo(1, 10, filtros);
    getTabela();
  }, []);

  const columns = [
    {
      title: "Nome",
      dataIndex: "nome",
    },
    { title: "Domínio", dataIndex: "nome_dominio" },
    { title: "Tabela", dataIndex: "nome_tabela" },
  ];

  const getCampo = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await TabelaRequest.getClasseId(
        id,
        page,
        pageSize,
        params
      );
      if (response.status === 200) {
        const { results, count } = response.data;
        setCampo(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getTabela = async () => {
    try {
      const response = await TabelaRequest.getTabelaId(id);
      if (response.status === 200) {
        setTabelaNome(response.data.nome);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const selecionarLinha = (selectedRowKeys, selectedRows) => {
    setCampoSelecionado(selectedRows[0]);
  };

  const handleOk = () => {
    onSelecionado(campoSelecionado);
  };

  const onPesquisarClick = (value) => {
    setFiltros(value);
    getCampo(1, 10, value);
  };

  const limpar = () => {
    setFiltros({});
    form.resetFields();
    getCampo(1, 10, {});
  };

  return (
    <Modal visible={openModal} onCancel={onCancel} width="50%" onOk={handleOk}>
      <Form form={form} layout="vertical" onFinish={onPesquisarClick}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "30%" }}>
            <Form.Item
              name="dominio_nome"
              label="Domínio:"
              style={{ fontWeight: "500" }}
            >
              <Input />
            </Form.Item>
          </div>

          <div style={{ width: "30%" }}>
            <Form.Item name="nome" label="Campo:" style={{ fontWeight: "500" }}>
              <Input />
            </Form.Item>
          </div>

          <div style={{ width: "30%" }}>
            <Form.Item label="Tabela:" style={{ fontWeight: "500" }}>
              <Input value={tabelaNome} readOnly />
            </Form.Item>
          </div>
        </div>

        <Divider />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            icon={<SearchOutlined />}
            htmlType="submit"
            style={{
              color: "white",
              background: colors.secondary,
              fontWeight: "500",
            }}
            className="botao"
          >
            Pesquisar
          </Button>
          <Button icon={<CloseOutlined />} onClick={limpar}>
            Limpar
          </Button>
        </div>

        <div style={{ marginTop: 20 }}>
          <Table
            columns={columns}
            rowKey="id"
            rowSelection={{
              type: "radio",
              onChange: selecionarLinha,
            }}
            dataSource={campo}
            pagination={{
              total: totalRecords,
              showTotal: (total) => (
                <span
                  style={{ fontWeight: "500", position: "absolute", left: "4%" }}
                >
                  Quantidade: {total}
                </span>
              ),
              onChange: (page, pageSize) => getCampo(page, pageSize, filtros),
            }}
            loading={loading}
            scroll={{ x: true }}
          />
        </div>
      </Form>
    </Modal>
  );
};
