import { urlBase } from "../../../Constante/Url";
import axios from "axios";
import { UserTockem } from "../../../Utils/autenticacao";

const getConfig = (params) => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
};

const getArquivos = async (page, pageSize, params) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}Arquivo/?limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
};

const getArquivosId = async (id) => {
  const url = `${urlBase}Arquivo/${id}/`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
};

const postArquivos = async (data) => {
  const url = `${urlBase}Arquivo/`;
  const config = getConfig();
  const response = await axios.post(url, data, config);
  return response;
};

const patchArquivos = async (id, data) => {
  const url = `${urlBase}Arquivo/${id}/atribuir-autorizacao/`;
  const config = getConfig();
  const response = await axios.patch(url, data, config);
  return response;
};

const deleteArquivos = async (id) => {
  const url = `${urlBase}Arquivo/${id}`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
};

export const arquivosRequest = {
  getArquivos,
  postArquivos,
  getArquivosId,
  patchArquivos,
  deleteArquivos,
};
