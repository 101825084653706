import {
  Button,
  Divider,
  Drawer,
  Form,
  Radio,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import EmolumentoPeriodoRequest from "./EmolumentoPeriodoRequest";
import {
  FilterOutlined,
  PlusOutlined,
  CloseOutlined,
  KeyOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { ufs } from "../../Constante/UFS";
import CadastroEmolumentoPeriodo from "./CadastroEmolumentoPeriodo";
import { ModalDelete } from "./modalDelete";
import { colors } from "../../Utils/temas";

const EmolumentoPeriodo = () => {
  const [emolumentoPeriodo, setEmolumentoPeriodo] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [id, setId] = useState(0);
  const [idDelete, setIdDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filtros, setFiltros] = useState([]);
  const [form_pesquisa] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await EmolumentoPeriodoRequest.getListaEmolumentoPeriodo(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      setEmolumentoPeriodo(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  function onInserirClick() {
    showModal();
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const optionsUf = ufs.map((uf) => {
    return {
      label: uf.sigla,
      value: uf.sigla,
    };
  });

  useEffect(() => {
    FetchRecords(1, 10, {});
  }, []);

  const dataFormatada = (data) => {
    const date = new Date(data);

    const formatacao = data === null ? "" : date.toLocaleDateString();
    return formatacao;
  };

  const columns = [
    {
      title: "Descrição",
      dataIndex: "descricao",
      ellipsis: true,
      onCell: () => ({
        style: { maxWidth: '100%' },
      }),
    },
    {
      title: "Situação",
      dataIndex: "situacao",
      render: (situacao) => {
        let situacao_descricao = "";
        let color = "";

        if (situacao === "A") {
          situacao_descricao = "Ativo";
          color = "green";
        } else {
          situacao_descricao = "Inativo";
          color = "red";
        }
        return <Tag color={color}> {situacao_descricao} </Tag>;
      },
    },
    {
      title: "UF",
      dataIndex: "uf",
    },
    {
      title: "Data Inicial",
      dataIndex: "data_inicial",
      render: (data_inicial) => dataFormatada(data_inicial),
    },
    {
      title: "Ações",
      key: "acoes",
      render: (record) => (
        <Space>
          <Tooltip title="Editar">
            <EditOutlined onClick={(_) => onEditEmolumentoPeriodo(record.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined
              onClick={(_) => onDeletarEmolumentoPeriodo(record.id)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  async function onEditEmolumentoPeriodo(id) {
    setId(id);
    setIsModalVisible(true);
  }

  function onDeletarEmolumentoPeriodo(id) {
    setOpenDelete(true);
    setIdDelete(id);
  }

  const Limpar = () => {
    setFiltros([]);
    form_pesquisa.resetFields();
    FetchRecords(1, 10, {});
    setDrawerVisible(false);
  };

  async function onPesquisarClick(value) {
    setFiltros(value);
    FetchRecords(1, 10, value);
    setDrawerVisible(false);
  }

  const onRemoverFiltros = (tag) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[tag];

    setFiltros(novosFiltros);
    FetchRecords(1, 10, novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tags = {
            situacao: "Situação",
            uf: "UF",
          };

          const tagsValue = {
            A: "Ativo",
            I: "Inativo",
            GO: "GO",
            AL: "AL",
            CE: "CE",
            PA: "PA",
          };

          const tagsColors = () => {
            if (value === "A") {
              return "green";
            } else if (value === "I") {
              return "red";
            } else return "orange";
          };

          return (
            <Tag
              key={key}
              closable
              onClose={() => onRemoverFiltros(key)}
              color={tagsColors(value)}
            >
              <text style={{ padding: 10 }}>
                {tags[key] + ": " + tagsValue[value]}
              </text>
            </Tag>
          );
        })}
    </>
  );

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between", padding: 5 }}
      >
        <Button
          onClick={onInserirClick}
          style={{
            fontWeight: "500",
            color: "white",
            background: colors.secondary,
          }}
          className="botao"
        >
          <PlusOutlined />
          Inserir
        </Button>

        <Button
          onClick={() =>
            setDrawerVisible((prevDrawerVisible) => !prevDrawerVisible)
          }
          style={{
            fontWeight: "500",
            color: "white",
            background: colors.secondary,
          }}
          className="botao"
        >
          <FilterOutlined />
          Filtros
        </Button>
        <Drawer
          width={500}
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
        >
          <Form
            form={form_pesquisa}
            layout="vertical"
            onFinish={onPesquisarClick}
            preserve={true}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item name="situacao" label="Situação">
                <Radio.Group>
                  <Radio value={"A"}>Ativo</Radio>
                  <Radio value={"I"}>Inativo</Radio>
                </Radio.Group>
              </Form.Item>

              <Divider
                type="vertical"
                style={{ height: 80, background: "#DCDCDC" }}
              />

              <Form.Item name="uf" label="UF" style={{ width: "50%" }}>
                <Select name="uf" options={optionsUf} />
              </Form.Item>
            </div>

            <Divider style={{ background: "#DCDCDC" }} />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
              >
                <KeyOutlined />
                Pesquisar
              </Button>

              <Button onClick={Limpar}>
                <CloseOutlined />
                Limpar
              </Button>
            </div>
          </Form>
        </Drawer>
      </div>
      <Table
        loading={loading}
        columns={columns}
        rowKey={"id"}
        dataSource={emolumentoPeriodo}
        pagination={{
          total: totalRecords,
          showTotal: (total) => (
            <span
              style={{ fontWeight: "500", position: "absolute", left: "4%" }}
            >
              Quantidade: {total}
            </span>
          ),
          onChange: (page, pagaSize) => {
            FetchRecords(page, pagaSize, filtros);
          },
        }}
        title={getTags}
        scroll={{ x: true }}
      />
      <CadastroEmolumentoPeriodo
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setId(null);
        }}
        optionsUf={optionsUf}
        estadoEmolumento={setEmolumentoPeriodo}
        id={id}
      />

      <ModalDelete
        onCancel={() => {
          setOpenDelete(false);
          setId(null);
        }}
        visible={openDelete}
        id={idDelete}
        estadoEmolumentoPer={setEmolumentoPeriodo}
      />
    </div>
  );
};

export default EmolumentoPeriodo;
