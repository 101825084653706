/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Select, Skeleton, Space, Table, Tabs } from "antd";
import { ufs } from "../../Constante/UFS";
import { colors } from "../../Utils/temas";
import { React, useEffect, useState } from "react";
import sistemaRequest from "../../Utils/sistemaRequest";
import EmolumentoItemRequest from "./EmolumentoItemRequest";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { ModalEmolumentItem } from "./componentes/modal";
import { ModalDelete } from "./componentes/modalDelete";
import { DrawerFilter } from "./componentes/drawerFilter";

const { TabPane } = Tabs;

export const EmolumentoItem = () => {
  const [sistemas, setSistemas] = useState([{ id: 0, nome_sistema: "" }]);
  const [emolumentos, setEmolumentos] = useState([
    {
      calcular_valor_outra_taxa1: false,
      selo_grupo_isento_id: null,
      sistema_descricao: "",
      selo_grupo_id: null,
      sistema_id: null,
      descricao: "",
      situacao: "",
      id: null,
      tipo: "",
      uf: "",
    },
  ]);
  const [emolumentosPeriodos, setEmolumentosPeriodos] = useState([
    { id: null, descricao: "" },
  ]);
  const [emolumentosItem, setEmolumentosItem] = useState([]);

  const [filtros, setFiltros] = useState({
    situacao: "A",
    sistema_id: null,
    uf: "GO",
  });
  const [filtrosEmolumentoItem, setFiltrosEmolumentoItem] = useState({});

  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openInsert, setOpenInsert] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loadingEmolument, setLoadingEmolument] = useState(false);
  const [loadingEmolumentItem, setLoadingEmolumentItem] = useState(false);

  const [totalRecords, setTotalRecords] = useState(null);
  const [id, setId] = useState(null);
  const [idDelete, setIdDelete] = useState(null);

  const [descricao, setDescricao] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getSistemas();
  }, []);

  useEffect(() => {
    if (filtros.uf) {
      getEmolumento(filtros);
    }
  }, [filtros.uf, filtros.sistema_id, filtros.descricao]);

  useEffect(() => {
    if (filtros.uf) {
      getEmolumentoPeriodo({ uf: filtros.uf });
    }
  }, [filtros.uf]);

  useEffect(() => {
    if (
      filtrosEmolumentoItem.emolumento_periodo_id &&
      filtrosEmolumentoItem.emolumento_id
    ) {
      getEmolumentoitem(1, 10, filtrosEmolumentoItem);
    }
  }, [filtrosEmolumentoItem]);

  const getSistemas = async () => {
    try {
      const response = await sistemaRequest.getSistemas();
      if (response.status === 200) {
        const { results } = response.data;

        const notSystems = [
          "ADMINISTRATIVO",
          "CAIXA",
          "FINANCEIRO",
          "LIVRO DIÁRIO",
        ];
        const sistemasFiltrados = results.filter(
          (item) => !notSystems.includes(item.nome_sistema)
        );
        setSistemas(sistemasFiltrados);
        setFiltros((prevState) => ({
          ...prevState,
          sistema_id: sistemasFiltrados[0]?.id,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const optionsUfs = ufs.map((item) => ({
    value: item.sigla,
    label: item.sigla,
  }));

  const getEmolumento = async (params) => {
    setLoadingEmolument(true);
    try {
      const response = await EmolumentoItemRequest.getBuscarEmolumento(params);
      if (response.status === 200) {
        const { data } = response;
        setEmolumentos(data);
        setLoadingEmolument(false);
        setFiltrosEmolumentoItem((prevState) => ({
          ...prevState,
          emolumento_id: data[0]?.id,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getEmolumentoPeriodo = async (params) => {
    setLoading(true);
    try {
      const response = await EmolumentoItemRequest.getEmolumentoPeriodo(params);
      if (response.status === 200) {
        const { results } = response.data;

        setEmolumentosPeriodos(results);
        setLoading(false);
        setFiltrosEmolumentoItem((prevState) => ({
          ...prevState,
          emolumento_periodo_id: results[0]?.id,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const optionsEmolumentosPeriodos = emolumentosPeriodos.map((item) => ({
    value: item.id,
    label: item.descricao,
  }));

  const getEmolumentoitem = async (page, pageSize, params) => {
    setLoadingEmolumentItem(true);
    try {
      const response = await EmolumentoItemRequest.getEmolumentoItem(
        page,
        pageSize,
        params
      );
      if (response.status === 200) {
        const { results, count } = response.data;
        setTotalRecords(count);
        setEmolumentosItem(results);
        setLoadingEmolumentItem(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatt = (valor) => {
    return valor?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const columns = [
    {
      title: "Valor Início",
      dataIndex: "valor_inicio",
      render: (valor) => formatt(valor),
    },
    {
      title: "Valor Fim",
      dataIndex: "valor_fim",
      render: (valor) => formatt(valor),
    },
    { title: "Código Selo", dataIndex: "codigo_selo" },
    { title: "Código Selo Anterior", dataIndex: "codigo_selo_anterior" },
    {
      title: "Valor Emolumento",
      dataIndex: "valor_emolumento",
      render: (value) => formatt(value),
    },
    {
      title: "Valor TX. Judiciária",
      dataIndex: "valor_taxa_judiciaria",
      render: (valor) => formatt(valor),
    },
    {
      title: "Ações",
      render: (values) => (
        <Space size="middle">
          <EditOutlined onClick={() => handleEdit(values.id)} />
          <DeleteOutlined onClick={() => handleDelete(values.id)} />
        </Space>
      ),
    },
  ];

  const handleEdit = (value) => {
    setOpenInsert(true);
    setId(value);
  };
  const handleDelete = (value) => {
    setOpenDelete(true);
    setIdDelete(value);
  };

  const handlePressEnter = (e) => {
    if (e.key === "Enter") {
      setFiltros((prevState) => ({ ...prevState, descricao: descricao }));
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    getEmolumentoitem(page, pageSize, filtrosEmolumentoItem);
  };

  return (
    <div>
      <div style={{ padding: 10 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <text style={{ fontWeight: "500" }}>UF:</text>
            <Select
              options={optionsUfs}
              style={{ width: 80 }}
              onChange={(e) =>
                setFiltros((prevState) => ({ ...prevState, uf: e }))
              }
              value={filtros.uf}
            />
          </div>

          {filtros.uf && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 20,
              }}
            >
              <text style={{ fontWeight: "500" }}>Emolumento Período:</text>
              <Select
                options={optionsEmolumentosPeriodos}
                style={{ width: 280 }}
                loading={loading}
                onChange={(e) =>
                  setFiltrosEmolumentoItem((prevState) => ({
                    ...prevState,
                    emolumento_periodo_id: e,
                  }))
                }
                value={filtrosEmolumentoItem.emolumento_periodo_id}
              />
            </div>
          )}
        </div>
      </div>

      <Tabs
        type="card"
        size="large"
        onChange={(e) =>
          setFiltros((prevState) => ({ ...prevState, sistema_id: e }))
        }
      >
        {sistemas.map((item) => (
          <TabPane tab={item.nome_sistema} key={item.id}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "row",
                maxHeight: "100vh",
              }}
            >
              <div
                style={{
                  width: "20%",
                  overflow: "auto",
                }}
              >
                <div style={{ paddingBottom: 10 }}>
                  <Input
                    style={{ borderRadius: 4 }}
                    onChange={(e) => setDescricao(e.target.value)}
                    onKeyDown={handlePressEnter}
                    value={descricao}
                  />
                </div>
                {loadingEmolument ? (
                  <Skeleton />
                ) : (
                  emolumentos &&
                  emolumentos.length > 0 &&
                  emolumentos[0].id !== null &&
                  emolumentos.map((item) => (
                    <div
                      style={{
                        border: "1px solid",
                        padding: 4,
                        borderRadius: 10,
                        marginBottom: 10,
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "100%",
                        boxShadow:
                          filtrosEmolumentoItem.emolumento_id === item.id
                            ? "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                            : null,
                        background:
                          filtrosEmolumentoItem.emolumento_id === item.id
                            ? colors.secondary
                            : null,
                        color:
                          filtrosEmolumentoItem.emolumento_id === item.id
                            ? "white"
                            : "black",
                        borderColor:
                          filtrosEmolumentoItem.emolumento_id === item.id
                            ? colors.secondary
                            : null,
                      }}
                      onClick={() => {
                        setFiltrosEmolumentoItem((prevState) => ({
                          ...prevState,
                          emolumento_id: item.id,
                        }));
                        setCurrentPage(1);
                      }}
                    >
                      <text
                        style={{
                          fontWeight: "500",
                          fontSize: 18,
                        }}
                      >
                        {item.descricao}
                      </text>
                    </div>
                  ))
                )}
              </div>

              <div style={{ width: "76%" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    style={{
                      marginBottom: 10,
                      fontWeight: "500",
                      background: colors.secondary,
                      color: "white",
                    }}
                    icon={<PlusOutlined />}
                    className="botao"
                    onClick={() => setOpenInsert(true)}
                    disabled={
                      !filtrosEmolumentoItem.emolumento_id ||
                      !filtrosEmolumentoItem.emolumento_periodo_id
                    }
                  >
                    Inserir
                  </Button>
                  <Button
                    icon={<FilterOutlined />}
                    style={{
                      background: colors.secondary,
                      color: "white",
                      fontWeight: "500",
                    }}
                    className="botao"
                    onClick={() => setOpenDrawer(true)}
                  >
                    Filtros
                  </Button>
                </div>

                <Table
                  dataSource={emolumentosItem}
                  columns={columns}
                  loading={loadingEmolumentItem}
                  pagination={{
                    total: totalRecords,
                    showTotal: (total) => (
                      <span
                        style={{ fontWeight: "500", position: "absolute", left: "4%" }}
                      >
                        Quantidade: {total}
                      </span>
                    ),
                    current: currentPage,
                    onChange: handlePaginationChange,
                  }}
                  scroll={{ x: true }}
                />
              </div>
            </div>
          </TabPane>
        ))}
      </Tabs>

      <ModalEmolumentItem
        visible={openInsert}
        onCancel={() => {
          setOpenInsert(false);
          setId(null);
        }}
        emolumento_id={filtrosEmolumentoItem.emolumento_id}
        emolumento_periodo_id={filtrosEmolumentoItem.emolumento_periodo_id}
        estadoEmolumentoItem={setEmolumentosItem}
        id={id}
      />
      <ModalDelete
        onCancel={() => setOpenDelete(false)}
        visible={openDelete}
        id={idDelete}
        estadoEmolumentoItem={() =>
          getEmolumentoitem(1, 10, filtrosEmolumentoItem)
        }
      />

      <DrawerFilter
        onClose={() => setOpenDrawer(false)}
        visible={openDrawer}
        valuesFilter={(e) => {
          setFiltrosEmolumentoItem((prevState) => ({
            ...prevState,
            codigo_selo: e,
          }));
          setOpenDrawer(false);
        }}
        clearFilter={(e) =>
          setFiltrosEmolumentoItem((prevState) => ({
            ...prevState,
            codigo_selo: e,
          }))
        }
      />
    </div>
  );
};
