/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { indexRequest } from "./indexRequest";
import { ModalDelete } from "./components/modalDelete";
import { colors } from "../../../Utils/temas";
import { DrawerFilter } from "./components/drawerFilter";
import { ModalInsert } from "./components/modalInsert";

export const Index = ({ id, nomeTabela }) => {
  const [index, setIndex] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [indexId, setIndexId] = useState(null);
  const [idDelete, setIdDelete] = useState(null);

  const [filtros, setFiltros] = useState({});

  useEffect(() => {
    fetchRequest(1, 10, filtros);
  }, [filtros]);

  const fetchRequest = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await indexRequest.getIndex(page, pageSize, id, params);
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setIndex(results);
        setTotalRecords(count);
        setLoading(false);
        setOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data alteração",
      dataIndex: "data_alteracao",
      render: (value) => {
        const data = new Date(value);
        const dataFormatada = data.toLocaleString().replace(",", "");
        return <text>{dataFormatada}</text>;
      },
    },
    { title: "Nome", dataIndex: "nome" },
    { title: "Campo", dataIndex: "campo__nome" },
    {
      title: "Em teste",
      dataIndex: "em_teste",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      title: "Ações",
      render: (record) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              handleEdit(record.id);
              setOpen(true);
            }}
          />
          <DeleteOutlined onClick={() => handleDelete(record.id)} />
        </Space>
      ),
    },
  ];

  const handleEdit = (values) => {
    setIndexId(values);
  };

  const handleDelete = (value) => {
    setIdDelete(value);
    setOpenDelete(true);
  };

  const onRemoverFiltro = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
  };

  const tags = {
    campo_nome: "Campo",
    em_teste: "Em teste",
    nome: "Nome",
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const getColors = () => {
            if (key === "nome") {
              return "geekblue";
            } else if (key === "campo_referencia_nome") {
              return "purple";
            } else if (key === "campo_nome") {
              return "magenta";
            }
          };

          const tagValores = {
            true: "Sim",
            false: "Não",
          };

          return (
            <>
              {value || value === false ? (
                <Tag
                  closable
                  onClose={() => onRemoverFiltro(key)}
                  color={
                    key === "em_teste"
                      ? value
                        ? "green"
                        : "red"
                      : getColors(key)
                  }
                  key={key}
                >
                  <text style={{ padding: 10 }}>
                    {tags[key] +
                      ": " +
                      (key === "em_teste" ? tagValores[value] : value)}
                  </text>
                </Tag>
              ) : null}
            </>
          );
        })}
    </>
  );

  return (
    <>
      <Table
        columns={columns}
        title={() => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => setOpen(true)}
              className="botao"
              style={{
                background: colors.secondary,
                fontWeight: "500",
                color: "white",
              }}
              icon={<PlusOutlined />}
            >
              Inserir
            </Button>

            <Button
              icon={<SearchOutlined />}
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
                marginLeft: 10,
              }}
              className="botao"
              onClick={() => setOpenFilter(true)}
            >
              Filtros
            </Button>

            <div style={{ marginLeft: 10 }}>{getTags()}</div>
          </div>
        )}
        dataSource={index}
        pagination={{
          total: totalRecords,
          showTotal: (total) => (
            <span
              style={{ fontWeight: "500", position: "absolute", left: "4%" }}
            >
              Quantidade: {total}
            </span>
          ),
          onChange: (page, pageSize) => fetchRequest(page, pageSize, filtros),
        }}
        loading={loading}
        scroll={{ x: true }}
      />

      <ModalDelete
        id={idDelete}
        onCancel={() => {
          setOpenDelete(false);
          setIdDelete(null);
        }}
        onDelete={setIndex}
        visible={openDelete}
      />

      <DrawerFilter
        visible={openFilter}
        onClose={() => setOpenFilter(false)}
        filtro={(e) => {
          setFiltros(e);
          setOpenFilter(false);
        }}
        limpar={() => {
          setOpenFilter(false);
          setFiltros({});
        }}
      />

      <ModalInsert
        id={id}
        estadoIndex={setIndex}
        initialValue={nomeTabela}
        onCancel={() => {
          setOpen(false);
          setIndexId(null);
        }}
        visible={open}
        idIndex={indexId}
      />
    </>
  );
};
