import { useEffect, useState, React } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import SeloGrupoPaiRequest from "./SeloGrupoPaiRequest";
import CadastroSeloGrupoPai from "./CadastroSeloGrupoPai";
import sistemaRequest from "../../Utils/sistemaRequest";
import { ufs } from "../../Constante/UFS";
import { ModalDelete } from "./modalDelete";
import { colors } from "../../Utils/temas";

const SeloGrupoPai = () => {
  const [seloGrupoPai, setSeloGrupoPai] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [id, setId] = useState(0);
  const [idDelete, setidDelete] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [sistemas, setSistemas] = useState([]);
  const [form] = Form.useForm();
  const [form_pesquisa] = Form.useForm();
  const [filtros, setFiltros] = useState([]);

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await SeloGrupoPaiRequest.getListaSeloGrupoPai(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      setSeloGrupoPai(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  async function onPesquisarClick(value) {
    setFiltros(value);
    FetchRecords(1, 10, value);
    setDrawerVisible(false);
  }

  const Limpar = () => {
    setFiltros([]);
    form_pesquisa.resetFields();
    FetchRecords(1, 10, {});
    setDrawerVisible(false);
  };

  async function CarregarSistema() {
    const response = await sistemaRequest.getSistemas();
    if (response.status === 200) {
      setSistemas(response.data.results);
    }
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  function onInserirClick() {
    showModal();
  }

  async function onEditSeloGrupoPai(id) {
    setIsModalVisible(true);
    setId(id);
  }

  function onDeletarSeloGrupoPai(id) {
    setOpenDelete(true);
    setidDelete(id);
  }

  const optionsSistema = sistemas.map((sistema) => {
    return {
      label: sistema.nome_sistema,
      value: sistema.id,
    };
  });

  const optionsUf = ufs.map((uf) => {
    return {
      label: uf.nome,
      value: uf.sigla,
    };
  });

  useEffect(() => {
    CarregarSistema();
    FetchRecords(1, 10, {});
  }, []);

  const columns = [
    {
      title: "Decrição",
      dataIndex: "selo_grupo_descricao",
      ellipsis: true,
      onCell: () => ({
        style: { maxWidth: "100%" },
      }),
    },
    {
      title: "Número",
      dataIndex: "selo_grupo_numero",
    },
    {
      title: "Ações",
      key: "acoes",
      render: (record) => (
        <Space>
          <Tooltip title="Editar">
            <EditOutlined onClick={(_) => onEditSeloGrupoPai(record.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={(_) => onDeletarSeloGrupoPai(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const tags = {
    selo_grupo__numero: "Número",
  };

  const onRemoveTags = (tag) => {
    const novoFiltro = { filtros };
    delete novoFiltro[tag];

    setFiltros(novoFiltro);
    FetchRecords(1, 10, novoFiltro);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          return (
            <Tag color="geekblue" closable onClose={() => onRemoveTags(key)}>
              <text style={{ padding: 10 }}>{tags[key] + ": " + value}</text>
            </Tag>
          );
        })}
    </>
  );

  return (
    <div style={{ height: "84vh" }}>
      <div
        style={{ display: "flex", justifyContent: "space-between", padding: 5 }}
      >
        <Button
          onClick={onInserirClick}
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          className="botao"
        >
          <PlusOutlined />
          Inserir
        </Button>

        <Button
          onClick={() =>
            setDrawerVisible((prevDrawerVisible) => !prevDrawerVisible)
          }
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          className="botao"
        >
          <FilterOutlined />
          Filtros
        </Button>
        <Drawer
          width={300}
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
        >
          <Form
            form={form_pesquisa}
            layout="vertical"
            onFinish={onPesquisarClick}
            preserve={true}
            style={{ height: "90%" }}
          >
            <Form.Item
              name="selo_grupo__numero"
              label="Número"
              style={{ width: "100%" }}
            >
              <Input />
            </Form.Item>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Divider style={{ background: "#DCDCDC" }} />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  htmlType="submit"
                  style={{
                    background: colors.secondary,
                    color: "white",
                    fontWeight: "500",
                  }}
                  className="botao"
                >
                  <KeyOutlined />
                  Pesquisar
                </Button>

                <Button onClick={Limpar}>
                  <CloseOutlined />
                  Limpar
                </Button>
              </div>
            </div>
          </Form>
        </Drawer>
      </div>
      <Table
        loading={loading}
        columns={columns}
        rowKey={"id"}
        dataSource={seloGrupoPai}
        pagination={{
          total: totalRecords,
          showTotal: (total) => (
            <span
              style={{ fontWeight: "500", position: "absolute", left: "4%" }}
            >
              Quantidade: {total}
            </span>
          ),
          onChange: (page, pagaSize) => {
            FetchRecords(page, pagaSize, filtros);
          },
        }}
        title={getTags}
        scroll={{ x: true }}
      />
      <CadastroSeloGrupoPai
        visible={isModalVisible}
        form={form}
        afterPost={setSeloGrupoPai}
        onCancel={() => {
          setIsModalVisible(false);
          setId(null);
        }}
        optionsUf={optionsUf}
        optionsSistema={optionsSistema}
        id={id}
      />

      <ModalDelete
        onCancel={() => setOpenDelete(false)}
        visible={openDelete}
        id={idDelete}
        afterDelete={setSeloGrupoPai}
      />
    </div>
  );
};

export default SeloGrupoPai;
