import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Radio,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  FilterOutlined,
  PlusOutlined,
  SearchOutlined,
  ClearOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import dominiosRequest from "./dominioRequest";
import { SelDominio } from "../../Selecao/Dominio/Sel_Dominio";
import { ModalDeletar } from "./componentes/modalDeletar";
import { colors } from "../../Utils/temas";
const { Group } = Radio;
const { Item } = Form;

export const CadastroDominio = () => {
  const [dominios, setDominios] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModalDeletar, setOpenModalDeletar] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);

  const [filtros, setFiltros] = useState({});

  const [idDelete, setIdDelete] = useState(null);
  const [dominioId, setDominioId] = useState(null);

  const [formFiltros] = Form.useForm();

  useEffect(() => {
    listaDominios(1, 10, filtros);
  }, [filtros]);

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data alteracao",
      dataIndex: "data_alteracao",
      render: (value) => {
        const data = new Date(value);
        const dataFormatada = data.toLocaleString().replace(",", "");
        return <text>{dataFormatada}</text>;
      },
    },
    { title: "Nome", dataIndex: "nome", key: "nome" },
    {
      title: "Tipo coluna Firebird",
      dataIndex: "tipo_coluna_firebird",
      key: "tipo_coluna_firebird",
    },
    {
      title: "Tamanho coluna Firebird",
      dataIndex: "tamanho_coluna_firebird",
      key: "tamanho_coluna_firebird",
    },
    {
      title: "Em teste",
      dataIndex: "em_teste",
      key: "em_teste",
      render: (valor) => {
        const valorFormat = () => {
          if (valor) {
            return "Sim";
          }
          return "Não";
        };
        return <Tag color={valor ? "green" : "red"}>{valorFormat()}</Tag>;
      },
    },
    {
      title: "Ações",
      key: "ações",
      render: (_, render) => {
        return (
          <div>
            <Tooltip title="Editar">
              <EditOutlined
                onClick={() => editarDominio(render.id)}
                style={{ marginRight: 10 }}
              />
            </Tooltip>

            <Tooltip title="Deletar">
              <DeleteOutlined onClick={() => deletarDominio(render.id)} />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const listaDominios = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await dominiosRequest.getDominios(
        page,
        pageSize,
        params
      );
      if (response.status === 200 || response.status === 201) {
        setDominios(response.data);
        let quantidade_total = response.data.length;
        setTotalRecords(quantidade_total);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onPesquisarClick = (values) => {
    setFiltros(values);
    listaDominios(1, 10, values);
    setOpenDrawer(false);
  };

  const limpar = () => {
    setFiltros({});
    formFiltros.resetFields();
    setOpenDrawer(false);
    listaDominios(1, 10, {});
  };

  const deletarDominio = (value) => {
    setIdDelete(value);
    setOpenModalDeletar(true);
  };

  const editarDominio = async (value) => {
    setOpenModal(true);
    setDominioId(value);
  };

  const tags = {
    nome: "Nome",
    em_teste: "Em teste",
  };

  const onRemoverTags = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
    listaDominios(1, 10, novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const colorTags = () => {
            if (key === "nome") return "orange";
            if (value === true) {
              return "green";
            }
            return "red";
          };

          if (key === "nome" && value !== "") {
            return (
              <Tag
                key={key}
                closable
                onClose={() => onRemoverTags(key)}
                color={colorTags(key)}
              >
                <text>{tags[key] + ": " + value}</text>
              </Tag>
            );
          }
          if (key === "em_teste") {
            return (
              <Tag
                closable
                onClose={() => onRemoverTags(key)}
                color={colorTags(key)}
              >
                <text>{`Em teste: ${value ? "Sim" : "Não"}`}</text>
              </Tag>
            );
          }
          return null;
        })}
    </>
  );

  return (
    <div>
      <div
        style={{ padding: 5, display: "flex", justifyContent: "space-between" }}
      >
        <Button
          onClick={() => setOpenModal(true)}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          <PlusOutlined /> Inserir
        </Button>

        <Button
          onClick={() => setOpenDrawer(true)}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          <FilterOutlined />
          Filtros
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={dominios}
        loading={loading}
        pagination={{
          total: totalRecords,
          showTotal: (total) => (
            <span
              style={{ fontWeight: "500", position: "absolute", left: "4%" }}
            >
              Quantidade: {total}
            </span>
          ),
          onChange: (page, pageSize) => {
            listaDominios(page, pageSize, filtros);
          },
        }}
        title={getTags}
        scroll={{ x: true }}
      />

      <Drawer
        title="Filtros"
        onClose={() => setOpenDrawer(false)}
        visible={openDrawer}
      >
        <Form
          form={formFiltros}
          layout="vertical"
          onFinish={onPesquisarClick}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            fontWeight: "500",
          }}
        >
          <Item label="Nome do domínio:" name="nome">
            <Input />
          </Item>

          <Item name="em_teste" label="Em teste:" style={{ margin: 0 }}>
            <Group>
              <Radio value={true}>Sim</Radio>
              <Radio value={false}>Não</Radio>
            </Group>
          </Item>

          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Divider />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
              >
                <SearchOutlined />
                Pesquisar
              </Button>
              <Button onClick={limpar}>
                <ClearOutlined /> Limpar
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>

      <SelDominio
        visible={openModal}
        onCancel={() => {
          setOpenModal(false);
          setDominioId(null);
        }}
        estadoDominio={setDominios}
        id={dominioId}
      />

      <ModalDeletar
        visible={openModalDeletar}
        onCancel={() => setOpenModalDeletar(false)}
        estadoDominio={setDominios}
        idDelete={idDelete}
      />
    </div>
  );
};
