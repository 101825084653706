/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Space, Table, Tooltip } from "antd";
import {
  CopyOutlined,
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import React, { useEffect, useState } from "react";

import { ModalDelete } from "./componentes/modalDelete";
import { ModalDetalhes } from "./componentes/modalDetalhes";
import CadastroAcessoRemoto from "./componentes/modalInserir";

import CartorioRequest from "../../CartorioRequest";

import { ufs } from "../../../../Constante/UFS";
import { colors } from "../../../../Utils/temas";

export const AcessoRemotoTab = ({ id, tab }) => {
  const [acessoRemoto, setAcessoRemoto] = useState([
    {
      cartorio_id: id,
      conexao_id: "",
      conexao_obs: null,
      conexao_senha: "",
      id: null,
      nome_cartorio: "",
      windows_senha: null,
      windows_usuario: "",
    },
  ]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openInsert, setOpenInsert] = useState(false);

  const [idAcesso, setIdAcesso] = useState(null);

  const [totalRecords, setTotalRecords] = useState(null);

  useEffect(() => {
    getAcessoRemoto(1, 10, filtro);
  }, []);

  const filtro = { cartorio_id: id };

  const header = [
    {
      key: "nome_cartorio",
      dataIndex: "nome_cartorio",
      title: "Nome cartório",
    },
    {
      title: "Tipo conexão",
      dataIndex: "tipo_conexao",
    },
    { key: "conexao_obs", dataIndex: "conexao_obs", title: "Observação" },
    {
      key: "acoes",
      title: "Ações",
      render: (values) => (
        <Space>
          <Tooltip title="Detalhes">
            <CopyOutlined onClick={() => handleOpenDetails(values.id)} />
          </Tooltip>

          <Tooltip title="Editar">
            <EditOutlined onClick={() => handleOpenEdit(values.id)} />
          </Tooltip>

          <Tooltip title="Excluir">
            <DeleteOutlined onClick={() => handleOpenDelete(values.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const getAcessoRemoto = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await CartorioRequest.getListaAcessoRemoto(
        page,
        pageSize,
        params
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setAcessoRemoto(results);
        setTotalRecords(count);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDetails = (valueId) => {
    setOpen(true);
    setIdAcesso(valueId);
  };

  const handleOpenEdit = (valueId) => {
    setOpenInsert(true);
    setIdAcesso(valueId);
  };

  const handleOpenDelete = (valueId) => {
    setOpenDelete(true);
    setIdAcesso(valueId);
  };

  const optionsUf = ufs.map((uf) => {
    return {
      label: uf.sigla,
      value: uf.sigla,
    };
  });

  return (
    <>
      <Table
        dataSource={acessoRemoto}
        columns={header}
        title={() => (
          <Space>
            <Button
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              onClick={() => setOpenInsert(true)}
              icon={<PlusOutlined />}
              className="botao"
            >
              Inserir
            </Button>
          </Space>
        )}
        loading={loading}
        pagination={{
          total: totalRecords,
          showTotal: (total) => (
            <span
              style={{ fontWeight: "500", position: "absolute", left: "4%" }}
            >
              Quantidade: {total}
            </span>
          ),
          onChange: (page, pageSize) => getAcessoRemoto(page, pageSize, filtro),
        }}
      />
      <ModalDetalhes
        id={idAcesso}
        onCancel={() => {
          setOpen(false);
          setIdAcesso(null);
        }}
        visible={open}
      />
      <ModalDelete
        onCancel={() => {
          setOpenDelete(false);
          setIdAcesso(null);
        }}
        id={idAcesso}
        visible={openDelete}
        estadoAcessoRemoto={setAcessoRemoto}
      />
      <CadastroAcessoRemoto
        estadoAcesso={setAcessoRemoto}
        onCancel={() => {
          setOpenInsert(false);
          setIdAcesso(null);
        }}
        visible={openInsert}
        optionsUf={optionsUf}
        id={id}
        idAcessoRemoto={idAcesso}
      />
    </>
  );
};
