import { Button, Divider, Form, Input, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { comandosRequest } from "../comandosRequest";

export const ModalAuthorization = ({ visible, onCancel, id }) => {
  const [values, setValues] = useState({
    id: "",
    descricao: "",
    hash_arquivo: "",
  });

  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      getComandos();
    }
  }, [id]);

  const getComandos = async () => {
    setLoading(true);
    try {
      const response = await comandosRequest.getComandosId(id);
      const { data } = response;
      if (response.status === 200) {
        setValues((prevState) => ({ ...prevState, id: data.id }));
        setValues((prevState) => ({ ...prevState, descricao: data.descricao }));
        setValues((prevState) => ({
          ...prevState,
          hash_arquivo: data.hash_arquivo,
        }));
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const atribuirAutorizacao = async (data) => {
    setConfirmLoading(true);
    try {
      const response = await comandosRequest.patchComandos(id, data);
      if (response.status === 200) {
        setConfirmLoading(false);
        onCancel();
        form.resetFields();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Autorização"
      footer={null}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      width="32%"
    >
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <text style={{ fontWeight: "500", marginRight: 4 }}>ID:</text>
              <text>{values.id}</text>
            </div>
            <div>
              <text style={{ fontWeight: "500", marginRight: 4 }}>
                Descrição:
              </text>
              <text>{values.descricao}</text>
            </div>
            <div>
              <text style={{ fontWeight: "500", marginRight: 4 }}>Hash:</text>
              <text>{values.hash_arquivo}</text>
            </div>
          </div>

          <Form
            layout="vertical"
            style={{ marginTop: 8, fontWeight: "500" }}
            onFinish={(values) =>
              atribuirAutorizacao({ autorizacao: values.autorizacao })
            }
            form={form}
          >
            <Form.Item
              label="Autorização:"
              name="autorizacao"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
            >
              <Input />
            </Form.Item>

            <Divider />

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => {
                    onCancel();
                    form.resetFields();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  Confirmar
                </Button>
              </div>
            </div>
          </Form>
        </>
      )}
    </Modal>
  );
};
