/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from "antd";
import React, { useEffect, useState } from "react";
import AtualizacaoRequest from "../../../../Atualizacao/atualizacao";

export const TabErros = ({ tipoErro, id }) => {
  const [errors, setErrors] = useState([]);

  const [loading, setLoading] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    getErros(1, 5);
  }, [tipoErro]);

  const columns = [
    {
      title: "Descrição",
      dataIndex: "descricao_erro",
    },
    {
      title: "Comando rodado",
      dataIndex: "comando_rodado",
    },
  ];

  const getErros = async (page, pageSize) => {
    setLoading(true);
    try {
      const response = await AtualizacaoRequest.getAtualizacaoErro(
        page,
        pageSize,
        id,
        tipoErro
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setErrors(results);
        setLoading(false);
        setTotalRecords(count);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={errors}
        loading={loading}
        pagination={{
          total: totalRecords,
          showTotal: (total) => (
            <span
              style={{ fontWeight: "500", position: "absolute", left: "4%" }}
            >
              Quantidade: {total}
            </span>
          ),
          pageSize: 5,
          onChange: (page, pageSize) => getErros(page, pageSize),
        }}
      />
    </>
  );
};
