import axios from "axios";
import { urlBase } from "../../../Constante/Url";
import { UserTockem } from "../../../Utils/autenticacao";

function getConfig(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

async function getAtualizacao(page, pageSize, params) {
  let skip = (page - 1) * pageSize;
  const url = urlBase + "atualizacao/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
  const response = await axios.get(url, config);
  return response;
}

async function getAtualizacaoId(id) {
  const url = urlBase + `atualizacao/${id}/`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
}

async function getAtualizacaoCartorio(page, pageSize, params) {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}atualizacao-cartorio/?limit=${pageSize}&offset=${skip}`;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
}
async function getAtualizacaoCartId(id) {
  const url = `${urlBase}atualizacao-cartorio/${id}`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
}
async function getAtualizacaoErro(page, pageSize, id, tipoErro) {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}atualizacao-cartorio-log-erro/?atualizacaocartorio_id=${id}&limit=${pageSize}&offset=${skip}&tipo_erro=${tipoErro}`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
}
const getBuscarErro = async (id) => {
  const url = `${urlBase}atualizacao-cartorio/${id}/buscar-tipo-atualizacao-erro/`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
};

const getListarUsuarios = async () => {
  const url = `${urlBase}usuario/registro/`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
};

async function deleteAtualizacaoCartorio(id) {
  const url = urlBase + `atualizacao-cartorio/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
}

async function deleteAtualizacao(id) {
  const url = urlBase + `atualizacao/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
}

async function postAtualizacao(data) {
  const url = urlBase + `atualizacao/`;
  const config = getConfig();
  const response = await axios.post(url, data, config);
  return response;
}

async function postAtualizacaoCartorio(data) {
  const url = urlBase + "atualizacao-cartorio/";
  const config = getConfig();
  const response = await axios.post(url, data, config);
  return response;
}

async function postExecutarAtualizacao(id) {
  const url = urlBase + `atualizacao/${id}/executar-atualizacao/`;
  const config = getConfig();
  const response = await axios.post(url, id, config);
  return response;
}

const getGerarPlanilha = async (atualizacao_id) => {
  const response = await axios.get(
    `${urlBase}atualizacao-cartorio/gerar-planilha/${atualizacao_id}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
      responseType: "blob",
    }
  );
  return response;
};

const AtualizacaoRequest = {
  getBuscarErro,
  getAtualizacao,
  getGerarPlanilha,
  getAtualizacaoId,
  getListarUsuarios,
  getAtualizacaoErro,
  getAtualizacaoCartId,
  getAtualizacaoCartorio,
  deleteAtualizacao,
  deleteAtualizacaoCartorio,
  postAtualizacao,
  postAtualizacaoCartorio,
  postExecutarAtualizacao,
};

export default AtualizacaoRequest;
