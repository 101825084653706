/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { triggerRequest } from "./triggerRequest";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ModalDelete } from "./components/modalDelete";
import { colors } from "../../../Utils/temas";
import { DrawerFilter } from "./components/drawerFilter";
import { ModalInsert } from "./components/modalInsert";

export const Trigger = ({ id }) => {
  const [triggers, setTriggers] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [idDelete, setIdDelete] = useState(null);
  const [idTriggers, setIdTriggers] = useState(null);

  const [filtros, setFiltros] = useState({});

  useEffect(() => {
    fetchRequest(1, 10, filtros);
  }, [filtros]);

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data alteração",
      dataIndex: "data_atualizacao",
      render: (value) => {
        const data = new Date(value);
        const dataFormatada = data.toLocaleString().replace(",", "");
        return <text>{dataFormatada}</text>;
      },
    },
    { title: "Nome", dataIndex: "nome" },
    {
      title: "Tipo Trigger",
      dataIndex: "tipo_trigger",
      render: (render) => {
        const codigoDescricao = {
          AI: "After Insert",
          AU: "After Update",
          AD: "After Delete",
          AIU: "After Insert Update",
          ATD: "After TODOS",
          BI: "Before Insert",
          BU: "Before Update",
          BD: "Before Delete",
        };

        return <>{codigoDescricao[render]}</>;
      },
    },
    {
      title: "Em teste",
      dataIndex: "em_teste",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      title: "Ações",
      render: (record) => {
        return (
          <Space size="middle">
            <EditOutlined onClick={() => handleEdit(record.id)} />
            <DeleteOutlined onClick={() => handleDelete(record.id)} />
          </Space>
        );
      },
    },
  ];

  const fetchRequest = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await triggerRequest.getTrigger(
        page,
        pageSize,
        id,
        params
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setTriggers(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (values) => {
    setIdTriggers(values);
    setOpen(true);
  };

  const handleDelete = (value) => {
    setIdDelete(value);
    setOpenDelete(true);
  };

  const tags = {
    nome: "Nome",
    em_teste: "Em teste",
  };

  const onRemoverFiltro = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const colorsTag = () => {
            if (key === "nome") {
              return "orange";
            } else if (key === "em_teste" && !value) {
              return "red";
            }
            return "green";
          };

          return (
            <>
              {value || value === false ? (
                <Tag
                  closable
                  onClose={() => onRemoverFiltro(key)}
                  key={key}
                  color={colorsTag()}
                >
                  <text style={{ padding: 10 }}>
                    {tags[key] +
                      ": " +
                      (key === "em_teste" ? (value ? "Sim" : "Não") : value)}
                  </text>
                </Tag>
              ) : null}
            </>
          );
        })}
    </>
  );

  return (
    <>
      <Table
        columns={columns}
        title={() => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => setOpen(true)}
              className="botao"
              style={{
                background: colors.secondary,
                fontWeight: "500",
                color: "white",
              }}
              icon={<PlusOutlined />}
            >
              Inserir
            </Button>

            <Button
              style={{
                background: colors.secondary,
                fontWeight: "500",
                color: "white",
                marginLeft: 10,
              }}
              icon={<SearchOutlined />}
              className="botao"
              onClick={() => setOpenFilter(true)}
            >
              Filtros
            </Button>

            <div style={{ marginLeft: 10 }}>{getTags()}</div>
          </div>
        )}
        dataSource={triggers}
        pagination={{
          total: totalRecords,
          showTotal: (total) => (
            <span
              style={{ fontWeight: "500", position: "absolute", left: "4%" }}
            >
              Quantidade: {total}
            </span>
          ),
          onChange: (page, pageSize) => fetchRequest(page, pageSize),
        }}
        loading={loading}
        scroll={{ x: true }}
      />

      <ModalDelete
        id={idDelete}
        onCancel={() => setOpenDelete(false)}
        visible={openDelete}
        estadoTrigger={setTriggers}
      />

      <DrawerFilter
        onClose={() => setOpenFilter(false)}
        visible={openFilter}
        filtros={(e) => {
          setFiltros(e);
          setOpenFilter(false);
        }}
        limpar={() => {
          setFiltros({});
          setOpenFilter(false);
        }}
      />

      <ModalInsert
        visible={open}
        estadoTriggers={setTriggers}
        id={id}
        onCancel={() => {
          setOpen(false);
          setIdTriggers(null);
        }}
        idTrigger={idTriggers}
      />
    </>
  );
};
