import { Button, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { colors } from "../../../Utils/temas";
import { FilterOutlined } from "@ant-design/icons";
import { requestForcada } from "./request";
import { DrawerFilter } from "./components/drawer";
import AtualizacaoRequest from "../Atualizacao/atualizacao";

export const AtualizacaoForcada = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [atualizacaoForcada, setAtualizacaoForcada] = useState([]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [filtros, setFiltros] = useState({
    cartorio_id: null,
    usuario_id: null,
    nome_cartorio: "",
  });

  const [totalRecords, setTotalRecords] = useState(null);

  useEffect(() => {
    getAtualizacaoForcada(1, 10, filtros);
  }, [filtros]);

  useEffect(() => {
    if (open) {
      listarUsuarios();
    }
  }, [open]);

  const getAtualizacaoForcada = async (page, pageSize, filtrosReq) => {
    setLoading(true);
    const response = await requestForcada.getAtualizacaoForcada(
      page,
      pageSize,
      filtrosReq
    );
    if (response.status === 200) {
      const { results, count } = response.data;
      setAtualizacaoForcada(results);
      setLoading(false);
      setTotalRecords(count);
    }
  };

  const dataFormatt = (dataAtt) => {
    const data = new Date(dataAtt);

    return data.toLocaleString().replace(",", "");
  };

  const columns = [
    {
      title: "Data atualização",
      dataIndex: "data_atualizacao",
      render: (dataAtt) => dataFormatt(dataAtt),
    },
    { title: "Usuário", dataIndex: "nome_usuario" },
    { title: "Cartório", dataIndex: "nome_cartorio", width: "40%" },
    { title: "Cidade", dataIndex: "cartorio_cidade" },
    { title: "UF", dataIndex: "cartorio_uf" },
  ];

  const listarUsuarios = async () => {
    try {
      const response = await AtualizacaoRequest.getListarUsuarios();
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setUsuarios(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onRemoveTags = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
  };

  const tags = {
    usuario_id: "Usuário",
    cartorio_id: "Cartório",
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined && value !== null)
        .map(([key, value]) => {
          const tagsColors = () => {
            if (key === "usuario_id") return "gold";
            if (key === "cartorio_id") return "orange";
          };

          const filtragemUsuario = usuarios.filter((item) => item.id === value);

          if (key === "usuario_id") {
            return (
              <Tag
                closable
                onClose={() => onRemoveTags(key)}
                color={tagsColors(key)}
                key={key}
              >
                <text style={{ padding: 10 }}>
                  Usuário:{" "}
                  {filtragemUsuario &&
                    filtragemUsuario.length > 0 &&
                    filtragemUsuario[0].nome}
                </text>
              </Tag>
            );
          }

          if (key === "nome_cartorio") {
            return null;
          }

          if (key === "cartorio_id") {
            return (
              <Tag
                closable
                onClose={() => onRemoveTags(key)}
                color={tagsColors(key)}
                key={key}
              >
                <text style={{ padding: 10 }}>
                  {tags[key] + ": " + filtros.nome_cartorio}
                </text>
              </Tag>
            );
          }

          return (
            <Tag
              closable
              onClose={() => onRemoveTags(key)}
              color={tagsColors(key)}
              key={key}
            >
              <text style={{ padding: 10 }}>{tags[key] + ": " + value}</text>
            </Tag>
          );
        })}
    </>
  );

  return (
    <div>
      <div style={{ padding: 10 }}>
        <Button
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          icon={<FilterOutlined />}
          onClick={() => setOpen(true)}
        >
          Filtros
        </Button>
      </div>

      <Table
        dataSource={atualizacaoForcada}
        columns={columns}
        loading={loading}
        title={getTags}
        pagination={{
          total: totalRecords,
          showTotal: (total) => (
            <span
              style={{ fontWeight: "500", position: "absolute", left: "4%" }}
            >
              Quantidade: {total}
            </span>
          ),
          onChange: (page, pageSize) =>
            getAtualizacaoForcada(page, pageSize, filtros),
        }}
        scroll={{ x: true }}
      />

      <DrawerFilter
        onClose={() => {
          setOpen(false);
          setFiltros({});
        }}
        visible={open}
        filtrar={(e) => {
          setFiltros(e);
          setOpen(false);
        }}
        usuarios={usuarios}
      />
    </div>
  );
};
