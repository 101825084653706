import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Table,
} from "antd";
import { KeyOutlined, CloseOutlined } from "@ant-design/icons";
import seloGrupoRequest from "../../Paginas/CadastroSeloGrupo/SeloGrupoRequest";
import { colors } from "../../Utils/temas";

function SelSeloGrupo({
  onSelecionado,
  visible,
  onCancel,
  optionsSistema,
  optionsUf,
}) {
  const [seloGrupo, setSeloGrupo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [linhaSelecionada, setLinhaSelecionada] = useState(null);
  const [form_pesquisa] = Form.useForm();
  const [filtros, setFiltros] = useState([]);

  function handleOk() {
    onSelecionado(linhaSelecionada);
    onCancel();
  }

  const columns = [
    {
      title: "Descrição",
      dataIndex: "descricao",
    },
    {
      title: "Descrição Completa",
      dataIndex: "descricao_completa",
    },
    {
      title: "Sistema",
      dataIndex: "sistema_descricao",
    },
    {
      title: "UF",
      dataIndex: "uf",
    },
  ];

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await seloGrupoRequest.getListaSeloGrupo(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      setSeloGrupo(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  function SelecionarLinha(selectedRowKeys, selectedRows) {
    setLinhaSelecionada(selectedRows[0]);
  }

  async function onPesquisarClick(value) {
    setFiltros(value);
    FetchRecords(1, 10, value);
  }

  const Limpar = () => {
    setFiltros([]);
    form_pesquisa.resetFields();
    FetchRecords(1, 10, {});
  };

  useEffect(() => {
    FetchRecords(1, 10, {});
  }, []);

  return (
    <Modal
      width={800}
      title="Selecionar selo Grupo"
      visible={visible}
      onOk={() => {
        handleOk();
      }}
      onCancel={onCancel}
      destroyOnClose={true}
      centered
      bodyStyle={{ height: "80vh", overflowX: "scroll" }}
    >
      <>
        <Form
          form={form_pesquisa}
          layout="vertical"
          onFinish={onPesquisarClick}
          preserve={true}
        >
          <Row gutter={10}>
            <Col span={5}>
              <Form.Item name="numero" label="Numero">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="sistema_id" label="Sistema">
                <Select options={optionsSistema} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="situacao" label="Situação">
                <Radio.Group>
                  <Radio value={"A"}>Ativo</Radio>
                  <Radio value={"F"}>Inativo</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="uf" label="UF">
                <Select options={optionsUf} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  fontWeight: "500",
                  color: "white",
                }}
                className="botao"
              >
                <KeyOutlined />
                Pesquisar
              </Button>
            </Col>

            <Col span={3} push={9}>
              <Button onClick={Limpar}>
                <CloseOutlined />
                Limpar
              </Button>
            </Col>
          </Row>
        </Form>
      </>
      <Table
        loading={loading}
        columns={columns}
        size="small"
        rowKey={"id"}
        rowSelection={{
          type: "radio",
          onChange: SelecionarLinha,
        }}
        dataSource={seloGrupo}
        pagination={{
          total: totalRecords,
          showTotal: (total) => (
            <span
              style={{ fontWeight: "500", position: "absolute", left: "4%" }}
            >
              Quantidade: {total}
            </span>
          ),
          onChange: (page, pagaSize) => {
            FetchRecords(page, pagaSize, filtros);
          },
        }}
      />
    </Modal>
  );
}
export default SelSeloGrupo;
